export function sliderSnap() {
  const sliders = document.querySelectorAll('.js-slider-snap')

  if (!sliders.length) return

  sliders.forEach(slider => {
    const track = slider.querySelector('.js-slider-snap-track')
    const prev = slider.querySelector('.js-slider-snap-prev')
    const next = slider.querySelector('.js-slider-snap-next')
    const dots = slider.querySelectorAll('.js-slider-snap-dot')

    const checkArrowsState = () => {
      const trackScrollWidth = track.scrollWidth
      const trackOuterWidth = track.clientWidth

      prev.removeAttribute('disabled')
      next.removeAttribute('disabled')

      if (0 >= track.scrollLeft) {
        prev.setAttribute('disabled', '')
      }

      if (track.scrollLeft === trackScrollWidth - trackOuterWidth) {
        next.setAttribute('disabled', '')
      }
    }

    checkArrowsState()

    track.addEventListener('scroll', () => {
      checkArrowsState()
    })

    prev.addEventListener('click', () => {
      navigate('backward')
    })

    next.addEventListener('click', () => {
      navigate('forward')
    })

    let autoplay = setInterval(() => navigate('forward'), 6000)

    slider.addEventListener('mouseenter', () => {
      clearInterval(autoplay)

      autoplay = null
    })

    slider.addEventListener('mouseleave', () => {
      autoplay = setInterval(() => navigate('forward'), 6000)
    })

    dots.forEach((dot, index) => {
      dot.addEventListener('click', () => navigate(index))
      dot.addEventListener('mouseenter', () => {
        clearInterval(autoplay)

        autoplay = null
      })

      dot.addEventListener('mouseleave', () => {
        autoplay = setInterval(() => navigate('forward'), 6000)
      })
    })

    // Slide transition
    const getNewScrollPosition = arg => {
      const maxScrollLeft = track.scrollWidth - track.clientWidth
      if ('forward' === arg) {
        const x = track.scrollLeft + track.clientWidth

        return x <= maxScrollLeft ? x : 0
      } if ('backward' === arg) {
        const x = track.scrollLeft - track.clientWidth

        return 0 <= x ? x : maxScrollLeft
      } if ('number' === typeof arg) {
        const x = arg * track.clientWidth

        return x
      }
    }
    const navigate = direction => {
      track.scrollLeft = getNewScrollPosition(direction)
    }

    const slideObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const slideIndex = Number(entry.target.dataset.slideindex) - 1

          dots.forEach(dot => {
            dot.classList.remove('is-active')
          })

          dots[slideIndex].classList.add('is-active')
        }
      })
    }, { root: track, threshold: 0.1 })

    document.querySelectorAll('.js-slide-snap-slide').forEach(slide => {
      slideObserver.observe(slide);
    })
  })
}
