<template lang="pug">
  include ../mixins
  +b.action-button.display_block.A(:href="link")
    +e.button
      +b.de-icon--type_heart-empty.--color_secondary.--size_md
    +e.counter(v-if="amountItems > 0")
      +b.u-display--hidden.--block-xl.SPAN {{ amountItems }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'favorite-button',
  props: {
    amount: Number,
    link: String,
  },
  data() {
    return {
      amountItems: this.amount,
    }
  },
  watch: {
    'getActionsData.favorite': {
      handler(val) {
        this.amountItems = val
      },
    },
  },
  computed: {
    ...mapGetters('common', [
      'getActionsData',
    ]),
  },
}
</script>
