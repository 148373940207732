<template lang="pug">
  include ../mixins
  modal-handler(
    component-name="cart-modal"
    :object-item="objectItem"
    modal-class="modal modal--size_custom-1 modal--container modal--padding_sm"
  )
    template(#default="{ handler }")
      row(align="center")
        cell
          +b.action-button.BUTTON(@click="handler")
            +e.button
              +b.de-icon--type_cart.--color_secondary.--size_md
            +e.counter
              +b.u-display--hidden.--block-xl.SPAN {{ productsAmount }}
        +b.u-display--hidden.--block-xxl
          +b.CELL(v-if="productsAmount > 0")
            +b.de-text--color_secondary.--size_2xs.--variant_center.--height_sm.P {{ productsAmount }} &nbsp;
              span(v-word-case:goods="productsAmount" :key="productsAmount")
              span &nbsp;{{_('на сумму')}}
              span &nbsp; {{ totalPrice }}
              span &nbsp; {{_('грн')}}

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'cart-button',
  props: {
    amount: Number,
    total: Number,
    objectItem: Object,
  },
  data() {
    return {
      productsAmount: null,
      totalPrice: null,
    }
  },
  watch: {
    cart: {
      handler(nval) {
        this.productsAmount = nval.quantity
        this.totalPrice = nval.finalPrice
      },
    },
  },
  mounted() {
    this.productsAmount = this.amount
    this.totalPrice = this.total
  },
  computed: {
    ...mapGetters('common', {
      cart: 'getCartData',
    }),
  },
}
</script>
