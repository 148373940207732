<script>
export default {
  name: 'ui-hovered',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      activeMenu: this.active,
    }
  },
  methods: {
    overMenu() {
      this.activeMenu = true
    },
    leaveMenu() {
      this.activeMenu = false
    },
  },
  render() {
    return this.$scopedSlots.default({
      activeHover: this.activeMenu,
      over: this.overMenu,
      leave: this.leaveMenu,
    })
  },
}
</script>
