<template lang="pug">
  include ../mixins
  +b.search
    +e.input.INPUT(
      type="text"
      :value="searchQuery"
      :placeholder="placeholder"
      @input="setValue($event);debounceFn()"
      @focus="toggleMask"
      @keyup.enter="redirect"
    )
    +e.icon.BEM-BUTTON(
        block="de-icon"
        type="search"
        size="2sm"
        :disable="searchQuery.length === 0"
        @event="redirect"
      )
    +e.delete.pointer.u-display--hidden.--block-xl(
      @click="clearInput"
      v-if="searchQuery.length"
    )
    +e.line
    +b.search-results(v-if="isResponse && isShowResponse")
      +e.wrapper
        search-item(
          v-for="product in searchResponse.items" :key="product.id"
          :product-info="product"
          :object-item="objectItem"
        )
        +e.item(v-if="searchResponse.levels.length")
          +b.P.de-text.--color_main.--weight_bold.--height_xs.--size_sm.ma--bottom_2xs {{ _("Поиск в уровне") }}
          +b.g-row.--appearance_spaced.--space_xs
            +b.g-cell.g-cols--12(v-for="item in searchResponse.levels")
              +b.A.de-link.--color_main.--weight_light.--height_xs.--size_2xs(
                :href="item.link"
              ) {{ _("товары в уровне") }}
                |&nbsp;
                +b.SPAN.de-link.--color_main.--weight_bold.--height_xs.--size_2xs {{ item.title }}
        +e.item(v-if="searchResponse.series.length")
          +b.P.de-text.--color_main.--weight_bold.--height_xs.--size_sm.ma--bottom_2xs {{ _("Поиск в серии") }}
          +b.g-row.--appearance_spaced.--space_xs
            +b.g-cell.g-cols--12(v-for="item in searchResponse.series")
              +b.A.de-link.--color_main.--weight_light.--height_xs.--size_2xs(
                :href="item.link"
              ) {{ _("товары в серии") }}
                |&nbsp;
                +b.SPAN.de-link.--color_main.--weight_bold.--height_xs.--size_2xs {{ item.title }}
        +e.item
          +b.de-text--color_secondary.--size_2xs.P(
            v-if="searchResponse.items.length == 0 && isResponse"
          ) {{_('Поиск не дал результатов')}}
          bem-text(
            v-if="searchResponse.items.length > 0 && isResponse"
            tag="p"
            size="2xs"
            weight="light"
            height="xs"
          ) {{_('Найдено')}} {{count}} &nbsp;
            span
              bem-button.display_contents(
                block="de-link"
                size="2xs"
                color="accent-1"
                height="xs"
                weight="light"
                @event="redirect()"
              ) {{_('Посмотреть все результаты поиска')}}
</template>

<script>
import { debounce } from 'lodash'
import { headerSearchResource } from '@services/search.service.js'

export default {
  name: 'header-search',
  components: {
    'search-item': () => import('@app/Search/SearchItem'),
  },
  props: {
    objectItem: Object,
    link: String,
  },
  data() {
    return {
      placeholder: '',
      searchQuery: '',
      count: 5,
      isResponse: false,
      isShowResponse: false,
      isLimit: false,
      searchResponse: {
        items: [],
        levels: [],
        series: [],
      },
      debounceFn: null,
    }
  },
  mounted() {
    const timeout = 500
    this.debounceFn = debounce(this.searchRequest, timeout)
    this.setPlaceholder()
  },
  methods: {
    setValue(e) {
      this.searchQuery = e.target.value
    },
    searchRequest() {
      this.$nextTick(() => {
        if (3 <= this.searchQuery.length) {
          const formdata = { search: this.searchQuery }
          headerSearchResource.execute({}, formdata).then(({ data }) => {
            this.searchResponse = data
            this.isResponse = true
            this.isShowResponse = true
            this.count = data.count
          })
        } else {
          this.isResponse = false
        }
      })
    },
    setPlaceholder() {
      this.placeholder =
        window.matchMedia('(min-width: 1200px)').matches ?
          this._('Введите название товара, автора, ISBN номер') :
          this._('Введите название товара')
    },
    redirect() {
      if (0 < this.searchQuery.length) {
        const path = `${this.link}search=${this.searchQuery}/`
        window.location.replace(path)
      }
    },
    clearInput() {
      this.searchQuery = ''
      this.isShowResponse = false
      this.isResponse = false
      this.searchResponse = {
        items: [],
        levels: [],
        series: [],
      }
    },
    toggleMask() {
      const timeout = 200
      const [mask] = document.getElementsByClassName('js-mask')
      mask.classList.toggle('is-active')
      setTimeout(() => {
        this.isShowResponse = !this.isShowResponse
      }, timeout)
    },
  },
}
</script>
