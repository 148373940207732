<template lang="pug">
  include ../mixins

  +b.action-button.display_block.A(:href="link")
    +e.button
      +b.de-icon--type_clock.--color_secondary.--size_md
    +e.counter
      +b.u-display--hidden.--block-xl.SPAN {{ amount }}
</template>

<script>
export default {
  name: 'tracked-button',
  props: {
    link: String,
    amount: Number,
  },
}
</script>
