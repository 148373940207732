<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    news-subscription-form-controller(
      :formdata="formdata"
      @handle-response="handleSubmit($event)"
    )
      template(#default="{ categoriesList, send, isLoad, handleSelection, checkCurrentOption }")
        row(align="end" justify="between")
          cell.ma--bottom_sm-till-xl(cols="12 6-sm 4-xl")
            +b.news-subscription
              control-input-pure(
                rules="custom_email|required"
                type="email"
                input-mode="email"
                :placeholder="_('Е-mail')"
                v-model="formdata.email"
                class-name="news-subscription__input"
                :required="true"
                name="email"
              )
              +e.icon.de-icon--type_mail.--size_sm.--color_accent-1.I
          cell.ma--bottom_sm-till-xl(cols="12 6-sm 4-xl")
            +b.common-multiselect--fullwidth.--white.form
              control-multiselect(
                :options="categoriesList"
                track-by="id"
                label="title"
                :allow-empty="true"
                label-class="form__label"
                v-model="formdata.category"
                :placeholder="_('Тема')"
                :required="false"
                :multiple="true"
                @input="handleSelection($event)"
                @select="checkCurrentOption($event)"
                name="category"
              )
                template(#noResult)
                  span {{ _("Елементи не знайдено") }}
                template(#noOptions)
                  span {{ _("Список порожній") }}
          cell(cols="12 3-xl")
            +e.button.de-text--size_2xs.BEM-BUTTON(
              appearance="fill-accent-3"
              space="sm"
              rounded="sm"
              width="full"
              :disable="isLoad"
              @event="validateBeforeSubmit(send)"
            ) {{_('Подписаться')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'

export default {
  name: 'news-subscription-form',
  mixins: [FormMixin],
  components: {
    'news-subscription-form-controller': () => import('./NewsSubscriptionController.vue'),
  },
  watch: {
    user: {
      handler(nval) {
        this.formdata = this.fillFormdata(nval)
      },
    },
  },
  data() {
    return {
      formdata: {
        email: '',
        category: null,
      },
    }
  },
  methods: {
    handleSubmit(res) {
      this.handleResponse({ modal: true, res })
    },
  },
}
</script>
