<template lang="pug">
  include ../mixins
  cell
    modal-handler(
      component-name="set-location-form"
      modal-class="modal modal--size_lg modal--full-container modal--padding_sm"
      :object-item="{ mainPage: mainPage, city: location }"
      :after-modal-request="updateLocation"
    )
      template(#default="{ handler }")
        bem-button.display_block(
          block="de-link"
          color="white"
          size="2xs"
          variant="underline"
          @event="handler"
        ) {{ location.description }}
</template>

<script>
export default {
  name: 'set-location',
  props: {
    location: Object,
    mainPage: String,
  },
  methods: {
    updateLocation(payload) {
      this.location = payload
      const pathName = window.location.pathname
      const [, slug] = pathName.split('/')
      if ('order' !== slug) {
        window.location.reload()
      }
    },
  },
}
</script>
