<template lang="pug">
  include ../mixins
  +b.product-order
    +e.part
      +e.main
        slot(name="price")
      +e.additional(v-if="limitAmount || preorder")
        ui-counter.counter--variant_1(
          :counter-value="1"
          :is-disabled="isDisabled"
          :limit="limit"
          @input-value="amount = $event"
          @count="amount + $event"
          @is-counter-error="isCounterError = $event"
        )
      +e.additional(v-if="$slots.preOrder")
        slot(name="preOrder")
    +e.part.--actions(v-if="$slots.oneClick || limitAmount || preorder")
      +e.main.--actions(v-if="limitAmount || preorder")
        buy-action(
          :product-id="productId"
          :object-item="{ ...objectItem, amount }"
        )
          template(#default="{ add }")
            bem-button(
              appearance="fill-accent-2"
              :space=["sm-icon", "md-icon"]
              rounded="sm"
              width="full"
              :disable="isCounterError === true"
              @event="add"
            )
              row(justify="center" align="center" appearance="nowrap")
                cell.ma--right_2xs
                  bem-text(
                    tag="p"
                    color="white"
                    size="2xs"
                    weight="bold"
                  ) {{ preorder ? _("Предзаказ") : _('Купить') }}
                cell
                  +b.inliner--center
                    bem-icon(
                      :type="preorder ? 'box' : 'cart'"
                      size="sm"
                      color="white"
                    )
      slot(name="oneClick" :disable="isCounterError === true")
</template>

<script>
export default {
  name: 'buy-button',
  components: {
    'ui-counter': () => import('@app/UiElements/UiCounter'),
  },
  props: {
    productId: Number,
    objectItem: Object,
    limitAmount: Number,
    preorder: Boolean,
  },
  data() {
    return {
      amount: 1,
      isCounterError: false,
    }
  },

  computed: {
    isDisabled() {
      return this.preorder ? 999 < this.amount : this.amount >= this.limitAmount
    },

    limit() {
      return this.preorder ? 999 : this.limitAmount
    },
  },
}
</script>
