import VueI18n from 'vue-i18n'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import Components from '@app'
import ResourceComponents from '@/js/resource'

export function install(Vue) {
  Vue.use(VueI18n)
  Vue.use(Vuex)
  Vue.use(VueRouter)
  Vue.use(Components)
  Vue.use(ResourceComponents)
}

export default { install }
