<template lang="pug">
  include ../mixins
  bem-button(
    block="de-icon"
    :type="type"
    color="accent-1"
    size="md"
    @event="$emit('action', $event)"
  )
</template>

<script>
export default {
  name: 'input-action',
  props: {
    type: String,
  },
}
</script>
