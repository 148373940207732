<template lang="pug">
  include ../mixins
  div
    +b.de-card--space_3sm.--color_white.ma--bottom_sm(
      v-if="infoIsVisible"
    )
      bem-text.ma--bottom_md(
        tag="h1"
        color="black"
        size="2md"
        weight="light"
      ) {{ title }}
      +b.t-content.ma--bottom_sm(v-html="content")
      row
        cell(cols="12 6-md 4-xl")
          bem-text.ma--bottom_sm-till-xl(
            tag="p"
            color="secondary"
            height="xs"
            size="3xs"
          ) {{_('*Скидки не суммируются (считается та, которая больше) и не распространяются на уцененные товары.')}}
        cell(cols="12 6-md 4-xl")
          bem-text(
            tag="p"
            color="secondary"
            height="xs"
            size="3xs"
          ) {{_('**Персональная скидка не суммируется со скидкой по промокоду.')}}
    +b.de-card--space_3sm.--color_white.ma--bottom_sm
      bonus-info(
        v-if="bonusData.length > 0"
        :amount="total"
        :bonusData="bonusData"
      )
    +b.de-card--space_3sm.--color_white
      row
        cell(cols="12 auto-md")
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
          ) {{_('Сумма покупок:')}}
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
            weight="bold"
          ) &nbsp; {{ total }} {{_('грн')}}
        cell(cols="12 auto-md")
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
          ) {{_('Текущая скидка:')}}
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
            color="accent-2"
            weight="bold"
          ) &nbsp; {{ currentPercent }} %
        cell(cols="12 auto-xxl")
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
          ) {{_('Остаток до след. скидки:')}}
          bem-text(
            height="sm"
            tag="span"
            size="3sm"
            weight="bold"
          ) &nbsp; {{ leftToBonus }} {{_('грн')}}

</template>

<script>
import { bonusDataResource } from '@services/cabinet.service.js'

export default {
  name: 'cabinet-bonuses',
  props: {
    infoIsVisible: {
      default: true,
    },
  },
  data() {
    return {
      content: '',
      title: '',
      bonusData: [],
      leftToBonus: null,
      currentPercent: null,
      total: null,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      return bonusDataResource.execute().then(res => {
        const { data: { item } } = res
        const {
          bonusRange, title, description, userLeft, userProc, userTotal,
        } = item

        this.bonusData = bonusRange
        this.title = title
        this.content = description
        this.leftToBonus = userLeft
        this.currentPercent = userProc
        this.total = userTotal
      })
    },
  },
}
</script>
