<template lang="pug">
  include ../mixins
  div
    component(
      :is="component"
      :object-item="objectItem"
      @close="closeHandler"
      @handle-function="handleFunction($event)"
    )
</template>

<script>
export default {
  name: 'common-modal',
  props: {
    component: {
      required: false,
    },
    objectItem: {
      required: false,
    },
    afterModalRequest: {
      required: false,
      type: Function,
    },
  },
  methods: {
    closeHandler() {
      this.$modal.hide('modal')
      // const body = document.getElementById('body')
      // body.classList.remove('disable-scroll')
    },
    handleFunction(payload) {
      if (this.afterModalRequest) this.afterModalRequest(payload)
    },
  },
}
</script>
