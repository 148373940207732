<script>
export default {
  name: 'ui-dropdown',
  props: {
    active: {
      default: false,
      type: Boolean,
    },
    arrow: {
      default: false,
      type: Boolean,
    },
    /* media-query */
    media: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      activeState: this.active,
      activeArrow: this.arrow,
      mediaQuery: this.media,
    }
  },
  watch: {
    active(nval) {
      this.activeState = nval
    },
  },
  mounted() {
    if (this.mediaQuery) {
      if (window.matchMedia(`(min-width: ${this.mediaQuery}px)`).matches) {
        this.activeState = true
      }
    }
  },
  methods: {
    toggleActive() {
      this.activeState = !this.activeState
      this.activeArrow = !this.activeArrow
    },
  },
  render() {
    return this.$scopedSlots.default({
      active: this.activeState,
      click: this.toggleActive,
      arrow: this.activeArrow,
    })
  },
}
</script>
