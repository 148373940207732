<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(@click="$emit('close')")
    +b.ma.--bottom_md(v-if="objectItem.image")
      +b.IMG.display_block(:src="objectItem.image")
    bem-text.ma--bottom_sm(
      v-if="objectItem.title"
      tag="h3"
      color="main"
      size="2md"
      height="xs"
      weight="bold"
    ) {{ objectItem.title }}
    +b.t-content(
      v-if="objectItem.content"
      v-html="objectItem.content"
    )
</template>

<script>
export default {
  name: 'live-book-modal',
  props: {
    objectItem: {},
  },
}
</script>
