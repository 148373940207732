import {
  createResource,
  postResource,
  getResource,
} from '@resource/resource'

import { CABINET } from './paths'

export const userSettingsResource = createResource(
  CABINET.USER_DETAIL, getResource
)

export const userUpdateResource = createResource(
  CABINET.USER_UPDATE, postResource
)

export const deliveryDetailResource = createResource(
  CABINET.DELIVERY_DETAIL, getResource
)

export const deliveryUpdateResorce = createResource(
  CABINET.DELIVERY_UPDATE, postResource
)

export const trackedListResource = createResource(
  CABINET.TRACKED_LIST, getResource
)

export const trackedDeleteResource = createResource(
  CABINET.TRACKED_DELETE, postResource
)

export const ordersListResource = createResource(
  CABINET.ORDERS_LIST, getResource
)

export const bonusDataResource = createResource(
  CABINET.BONUS, getResource
)
