<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_md(
      tag="p"
      weight="bold"
      size="md"
    ) {{_('Выберите свой город')}}
    validation-observer.position--relative(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.position--relative.ma--bottom_2sm.ma--bottom_sm
        control-multiselect-action.search-multiselect.search-multiselect--icon.ma--bottom_xs(
          :options="citiesList"
          v-model="formdata.city"
          track-by="id"
          label="description"
          rules="required"
          name="searchText"
          :allow-empty="false"
          label-class="form__label"
          :input-label="_('Введите населенный пункт Украины')"
          :placeholder="_('Введите название')"
          :searchable="true"
          :hide-selected="true"
          :internal-search="false"
          @search-change="delayRequest(citiesSearch, $event)"
          open-direction="bottom"
        )
          template(#noResult)
            span {{ _("За Вашим запитом нічого не знайдено") }}
          template(#noOptions)
            span {{ _("Почніть вводити назву") }}
        row(justify="end")
          cell
            bem-text(
              tag="p"
              size="xs"
              weight="light"
            ) {{_('Введите не менее 2-х символов')}}
      row(space="md-xl")
        cell(cols="12 6-sm")
          bem-link.de-text--size_2xs.de-text--weight_bold.de-text--variant_center.display_block(
            block="de-button"
            appearance="border-accent-1"
            space="sm"
            width="full"
            rounded="sm"
            :href="objectItem.mainPage"
          ) {{_('Перейти на главную')}}
        cell.order.order--sm.ma--bottom_sm-till-sm(cols="12 6-sm")
          bem-button.de-text--size_2xs(
            appearance="fill-accent-1"
            space="sm"
            width="full"
            rounded="sm"
            :disabled="isLoad"
            @event="validateBeforeSubmit()"
          ) {{_('Сохранить')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { setDefaultCityResource } from '@services/common.service.js'
import {
  cityResource,
} from '@services/delivery.service.js'

export default {
  name: 'set-location-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        city: '',
      },
      setTimer: {
        method: null,
        delay: 500,
      },
      citiesList: [],
    }
  },
  mounted() {
    this.formdata.city = this.objectItem.city
  },
  methods: {
    delayRequest(requestMethod, payload) {
      clearTimeout(this.setTimer.method)
      this.setTimer.method = setTimeout(async () => {
        await requestMethod(payload)
      }, this.setTimer.delay)
    },
    citiesSearch(query) {
      if (2 <= query.length) {
        return cityResource.execute({}, { searchText: query })
          .then(({ data: { item } }) => {
            this.citiesList = item.items
          })
      }
      return true
    },
    send(data) {
      return setDefaultCityResource.execute({}, { idCity: data.city.id })
        .then(() => {
          this.$emit('handle-function', this.formdata.city)
          this.$emit('close')
        })
    },
  },
}
</script>
