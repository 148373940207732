export function toggleSeoText() {
  const seoText = document.getElementsByClassName('js-seo')[0]
  const seoButtonOpen = document.getElementsByClassName('js-seo-open')[0]
  const seoButtonHide = document.getElementsByClassName('js-seo-hide')[0]
  const seoHeight = 94

  function toggleActivity() {
    if (seoText.classList.contains('line-clamp')) {
      seoButtonHide.classList.add('u-display--hidden')
      seoButtonOpen.classList.remove('u-display--hidden')
    } else {
      seoButtonHide.classList.remove('u-display--hidden')
      seoButtonOpen.classList.add('u-display--hidden')
    }
  }
  function checkSeoHeight() {
    if (seoText.clientHeight > seoHeight) {
      seoButtonOpen.classList.remove('u-display--hidden')
      seoText.classList.add('line-clamp')
    } else {
      seoButtonOpen.classList.add('u-display--hidden')
    }
  }
  if (seoText) {
    checkSeoHeight()
    seoButtonOpen.addEventListener('click', () => {
      seoText.classList.toggle('line-clamp')
      toggleActivity()
    }, { passive: true })
    seoButtonHide.addEventListener('click', () => {
      seoText.classList.toggle('line-clamp')
    }, { passive: true })
    window.addEventListener('resize', checkSeoHeight, { passive: true })
  }
}
