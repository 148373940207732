<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(@click="$emit('close')")
    bem-text.ma--bottom_sm(
      tag="h3"
      color="accent-1"
      size="md"
      height="xs"
      :variant="['center', 'uppercase']"
      weight="bold"
    ) {{title}}
    bem-text.ma---bottom_sm(
      tag="p"
      color="main"
      size="sm"
      height="xs"
      variant="center"
    ) {{text}}
</template>

<script>
export default {
  name: 'success-modal',
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
  },
}
</script>
