export function wysiwygHelper() {
  const [parent] = document.getElementsByClassName('t-content')
  if (parent) {
    let tables = parent.getElementsByTagName('table')
    let iframes = parent.getElementsByTagName('iframe')
    tables = Array.from(tables)
    iframes = Array.from(iframes)
    const elementWrapper = (arr, wrapClass) => {
      arr.forEach(el => {
        const wrapper = document.createElement('div')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add(wrapClass)
      })
    }
    elementWrapper(tables, 'table-wrapper')
    elementWrapper(iframes, 'iframe-wrapper')
  }
}
