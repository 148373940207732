<template lang="pug">
  include ../mixins
  +b.LABEL(
    :for='id'
    :class="labelClass"
  ) {{ inputLabel }}
    slot
    span(
      v-if='isRequired'
    ) &nbsp;*
</template>

<script>
export default {
  name: 'static-label',
  props: {
    labelText: {},
    labelClass: {},
    id: {
      type: String,
      required: false,
    },
    value: {},
    inputLabel: {
      type: String,
    },
    isRequired: {
      type: Boolean,
    },
  },
}
</script>
