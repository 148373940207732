<template lang="pug">
include ../mixins
vue-countdown(
  tag="div"
  :time="time"
  v-slot="{ days, hours, minutes }"
  :transform="transformSlotProps"
)
  +b.countdown
    +e.wrapper
      +e.item
        +e.P.count {{ days }}
        +e.P.hint {{ _("дней") }}
      +e.divider :
      +e.item
        +e.P.count {{ hours }}
        +e.P.hint {{ _("часов") }}
      +e.divider :
      +e.item
        +e.P.count {{ minutes }}
        +e.P.hint {{ _("минут") }}
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'

export default {
  name: 'ui-countdown',
  components: {
    VueCountdown,
  },
  props: {
    time: Number,
  },
  methods: {
    transformSlotProps(props) {
      const formattedProps = {}
      Object.entries(props).forEach(([key, value]) => {
        formattedProps[key] = 10 > value ? `0${value}` : String(value)
      })
      return formattedProps
    },
  },
}
</script>
