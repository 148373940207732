var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('news-subscription-form-controller',{attrs:{"formdata":_vm.formdata},on:{"handle-response":function($event){return _vm.handleSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var categoriesList = ref.categoriesList;
var send = ref.send;
var isLoad = ref.isLoad;
var handleSelection = ref.handleSelection;
var checkCurrentOption = ref.checkCurrentOption;
return [_c('row',{attrs:{"align":"end","justify":"between"}},[_c('cell',{staticClass:"ma--bottom_sm-till-xl",attrs:{"cols":"12 6-sm 4-xl"}},[_c('div',{staticClass:"news-subscription"},[_c('control-input-pure',{attrs:{"rules":"custom_email|required","type":"email","input-mode":"email","placeholder":_vm._('Е-mail'),"class-name":"news-subscription__input","required":true,"name":"email"},model:{value:(_vm.formdata.email),callback:function ($$v) {_vm.$set(_vm.formdata, "email", $$v)},expression:"formdata.email"}}),_c('i',{staticClass:"news-subscription__icon de-icon de-icon--type_mail de-icon--size_sm de-icon--color_accent-1"})],1)]),_c('cell',{staticClass:"ma--bottom_sm-till-xl",attrs:{"cols":"12 6-sm 4-xl"}},[_c('div',{staticClass:"common-multiselect common-multiselect--fullwidth common-multiselect--white form"},[_c('control-multiselect',{attrs:{"options":categoriesList,"track-by":"id","label":"title","allow-empty":true,"label-class":"form__label","placeholder":_vm._('Тема'),"required":false,"multiple":true,"name":"category"},on:{"input":function($event){return handleSelection($event)},"select":function($event){return checkCurrentOption($event)}},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Елементи не знайдено")))])]},proxy:true},{key:"noOptions",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Список порожній")))])]},proxy:true}],null,true),model:{value:(_vm.formdata.category),callback:function ($$v) {_vm.$set(_vm.formdata, "category", $$v)},expression:"formdata.category"}})],1)]),_c('cell',{attrs:{"cols":"12 3-xl"}},[_c('bem-button',{staticClass:"button de-text de-text--size_2xs",attrs:{"appearance":"fill-accent-3","space":"sm","rounded":"sm","width":"full","disable":isLoad},on:{"event":function($event){return _vm.validateBeforeSubmit(send)}}},[_vm._v(_vm._s(_vm._('Подписаться')))])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }