<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_3sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Купить в 1 клик')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          rules="username|required"
          type="text"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('Ваше имя')"
          v-model="formdata.firstName"
          :required="true"
          name="firstName"
        )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|phone_length"
          type="tel"
          inputmode="tel"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('Номер телефона')"
          v-model="formdata.phone"
          v-mask="'+38(###)### ## ##'"
          :required="true"
          name="phone"
        )
      bem-button.de-text--size_2xs(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disabled="isLoad || (formdata.phone === '' || formdata.firstName === '')"
        @event="validateBeforeSubmit()"
      ) {{_('Оформить')}}
      //- +b.de-button--appearance_fill-accent-1.--space_sm.--width_full.--rounded_sm.de-text--size_2xs.BUTTON(
      //-   @click="validateBeforeSubmit(this.send)"
      //- ) {{_('Оформить')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { mask } from 'vue-the-mask'
import { djangoToJsBoolean } from '@helpers/utils'
import { oneClickOrderResource } from '@services/order.service.js'
import { mapGetters, mapActions } from 'vuex'
import { USER_DATA_REQUEST } from '@store/action-types'

export default {
  name: 'one-click-buy-form',
  mixins: [FormMixin],
  directives: { mask },
  props: {
    objectItem: Object,
  },
  data() {
    return {
      products: JSON.parse(JSON.stringify(this.objectItem.products || [])),
      formdata: {
        firstName: '',
        phone: '',
        isFromCart: this.objectItem.isCart,
        idProduct: this.objectItem?.productId || '',
      },
      isLoad: false,
      isPrefillForm: djangoToJsBoolean(window.is_auth),
    }
  },
  watch: {
    user: {
      handler({ firstName, email }) {
        this.formdata.firstName = `${firstName}`
        this.formdata.email = email
      },
    },
  },
  mounted() {
    if (this.isAuth) this.userRequest()
  },
  methods: {
    send(data) {
      if (true === data.isFromCart) delete data.idProduct
      return oneClickOrderResource.execute({}, data).then(res => {
        this.handleResponse({ res, modal: true })
        this.products.forEach(el => {
          // eslint-disable-next-line
          ga('send', 'event', '1Click', 'send', el.title, el.price)
        })
      })
    },
    // fbEvent() {
    //   /* eslint-disable no-undef */
    //   fbq('trackCustom', 'buttonClicked')
    //   // FB.AppEvents.logEvent('buttonClicked')
    //   /* eslint-enable no-undef */
    // },
    ...mapActions('cabinet', {
      userRequest: USER_DATA_REQUEST,
    }),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
