class CategoryMenu {
  constructor() {
    const [menuElement] = document.getElementsByClassName('js-menu')
    this.menu = menuElement
  }

  open() {
    this.menu.classList.add('is-active')
  }

  close() {
    this.menu.classList.remove('is-active')
  }
}

function checkActivity() {
  const categoryMenu = new CategoryMenu()
  if (categoryMenu.menu.classList.contains('is-active')) return categoryMenu.close()
  return categoryMenu.open()
}

function closeMenu() {
  const categoryMenu = new CategoryMenu()
  return categoryMenu.close()
}

export function toggleCategoryMenu() {
  const [preview] = document.getElementsByClassName('js-categories')
  const [catalogButton] = document.getElementsByClassName('js-catalog-button')
  const [innerCategories] = document.getElementsByClassName('js-inner-categories')
  const [backgroundOfMenu] = document.getElementsByClassName('hovered-menu__wrapper')
  if (catalogButton) {
    if (preview) preview.addEventListener('mouseenter', checkActivity, { passive: true })
    if (preview) innerCategories.addEventListener('mouseleave', checkActivity, { passive: true })
    catalogButton.addEventListener('click', checkActivity, { passive: true })
    backgroundOfMenu.addEventListener('click', closeMenu, { passive: true })
  }
}
