export function handleHoveredMenu() {
  const titles = Array.from(document.getElementsByClassName('js-menu-title'))
  const wrappers = Array.from(document.getElementsByClassName('js-menu-wrapper'))
  wrappers[0].classList.add('is-active')

  titles.forEach((el, i) => {
    el.addEventListener('mouseenter', () => {
      wrappers.forEach(item => item.classList.remove('is-active'))
      wrappers[i].classList.add('is-active')
    }, { passive: true })
    el.addEventListener('mouseleave', () => {
      wrappers[i].classList.remove('is-active')
    }, { passive: true })
  })
}
