<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    row(space="3sm-xl")
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            rules="username|required"
            type="text"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Ваше имя')"
            v-model="formdata.name"
            :required="true"
            name="name"
          )
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            rules="required|phone_length"
            type="tel"
            inputmode="tel"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Номер телефона')"
            v-model="formdata.phone"
            v-mask="'+38(###)### ## ##'"
            :required="true"
            name="phone"
          )
      cell(cols="12 6-md")
        +b.form.position--relative.ma--bottom_sm
          control-multiselect-action.search-multiselect.search-multiselect--icon.ma--bottom_xs(
            :options="citiesList"
            v-model="formdata.city"
            track-by="id"
            label="description"
            rules="required"
            name="searchText"
            :allow-empty="false"
            label-class="form__label"
            :input-label="_('Введите населенный пункт Украины')"
            placeholder=" "
            :searchable="true"
            :hide-selected="true"
            :internal-search="false"
            @search-change="delayRequest(citiesSearch, $event)"
            open-direction="bottom",
          )
            template(#noResult)
              span {{ _("За Вашим запитом нічого не знайдено") }}
            template(#noOptions)
              span {{ _("Почніть вводити назву") }}
          row(justify="end")
            cell
              bem-text(
                tag="p"
                size="xs"
                weight="light"
              ) {{_('Введите не менее 2-х символов')}}
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            rules="required"
            type="text"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Учебное заведение')"
            v-model="formdata.institution"
            :required="true"
            name="institution"
          )
      cell(cols="12")
        +b.form.ma--bottom_sm
          control-textarea(
            type="textarea"
            label-class="form__label"
            class-name="form__textarea form__textarea--2sm"
            :input-label="_('Комментарий')"
            v-model="formdata.comment"
            :required="false"
            name="comment"
          )
    row(justify="end")
      cell(cols="12 6-sm 4-lg 3-xxl")
        bem-button.de-text--size_2xs(
          appearance="fill-accent-1"
          space="sm"
          width="full"
          rounded="sm"
          :disabled="isLoad"
          @event="validateBeforeSubmit()"
        ) {{_('Заказать оптом')}}
</template>

<script>
import { mask } from 'vue-the-mask'
import FormMixin from '@mixins/form.mixin.js'
import { valueFromSelect } from '@helpers/utils'
import { wholesaleFormResource } from '@services/common.service.js'
import {
  cityResource,
} from '@services/delivery.service.js'

const inputLength = 2

export default {
  name: 'wholesale-form',
  mixins: [FormMixin],
  directives: { mask },
  data() {
    return {
      formdata: {
        name: '',
        phone: '',
        comment: '',
        city: '',
        institution: '',
      },
      citiesList: [],
      setTimer: {
        method: null,
        delay: 500,
      },
    }
  },
  methods: {
    send(data) {
      const copyData = JSON.parse(JSON.stringify(data))
      copyData.city = valueFromSelect(copyData.city, 'id')
      return wholesaleFormResource.execute({}, copyData).then(res => {
        this.handleResponse({ modal: true, res })
        // eslint-disable-next-line
        ga('send', 'event', 'opt', 'send')
      })
    },
    citiesSearch(query) {
      if (inputLength <= query.length) {
        return cityResource.execute({}, { searchText: query })
          .then(({ data: { item } }) => {
            this.citiesList = item.items
          })
      }
      return true
    },
    delayRequest(requestMethod, payload) {
      clearTimeout(this.setTimer.method)
      this.setTimer.method = setTimeout(async () => {
        await requestMethod(payload)
      }, this.setTimer.delay)
    },
  },
}
</script>
