import Vuex from 'vuex'
import { common } from './modules/common.store.js'
import { cabinet } from './modules/cabinet.store.js'

export function createStore() {
  return new Vuex.Store({
    deep: true,
    modules: {
      common,
      cabinet,
    },
  })
}
