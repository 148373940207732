import {
  createResource,
  // postResource,
  getResource,
} from '@resource/resource'

import { CATALOG } from './paths'

export const catalogFiltersResource = createResource(
  CATALOG.CATALOG_FILTERS, getResource
)

export const catalogSeriesResource = createResource(
  CATALOG.CATALOG_SERIES, getResource
)

export const catalogLevelsResource = createResource(
  CATALOG.CATALOG_LEVELS, getResource
)

export const catalogCardsResource = createResource(
  CATALOG.CATALOG_CARDS, getResource
)

export const promoProductCardsResource = createResource(
  CATALOG.CATALOG_PROMO_PRODUCT_CARDS, getResource
)

export const promoProductFiltersResource = createResource(
  CATALOG.CATALOG_PROMO_PRODUCT_FILTERS, getResource
)

export const certificateDetailResource = createResource(
  CATALOG.CERTIFICATE_DETAIL, getResource
)
