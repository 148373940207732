<script>
import { turnFavoriteResource } from '@services/actions.service.js'
import { djangoToJsBoolean } from '@helpers/utils'
import { mapActions } from 'vuex'
import { CHANGE_ACTION } from '@store/action-types'
import Toasted from 'vue-toasted'
import Vue from 'vue'

Vue.use(Toasted)

export default {
  name: 'favorite-action',
  props: {
    productId: {},
    isActive: {},
    objectItem: Object,
    isReload: {
      type: String,
      default: () => 'False',
    },
  },
  data() {
    return {
      isActiveProduct: false,
      isRelodAfterRequest: null,
    }
  },
  mounted() {
    if ('boolean' === typeof this.isActive) this.isActiveProduct = this.isActive
    else this.isActiveProduct = djangoToJsBoolean(this.isActive)
    this.isRelodAfterRequest = djangoToJsBoolean(this.isReload)
  },
  methods: {
    send() {
      console.log('send')
      return turnFavoriteResource.execute({}, { id_product: this.productId })
        .then(({ data: { item } }) => {
          this.isActiveProduct = item.create
          // reload after deleting item in favorite page
          if (true !== this.isRelodAfterRequest) {
            this.changeAction({ key: 'favorite', res: item.count })
            let message = ''
            if (true === this.isActiveProduct) message = this._('Товар добавлени в избранное')
            else message = this._('Товар удален из избранного')
            this.$toasted.show(message, {
              theme: 'bubble',
              position: 'bottom-left',
              duration: 5000,
              className: 'toaster--orange',
            })
          } else window.location.reload()
        })
    },
    ...mapActions('common', {
      changeAction: CHANGE_ACTION,
    }),
  },
  render() {
    return this.$scopedSlots.default({
      action: this.send,
      isActive: this.isActiveProduct,
    })
  },
}
</script>
