import {
  createResource,
  postResource,
  // getResource,
} from '@resource/resource'

import { LOCATIONS } from './paths'

export const cityResource = createResource(
  LOCATIONS.CITIES, postResource
)

export const warehousesResource = createResource(
  LOCATIONS.WAREHOUSES, postResource
)
export const rozetkaDepartmentsResource = createResource(
  LOCATIONS.ROZETKA_DEPARTMENTS
)

export const streetsResource = createResource(
  LOCATIONS.STREETS, postResource
)
