<template lang="pug">
  include ../mixins
  +b.span.error-text--input
    slot
</template>
<script>
export default {
  name: 'ErrorComponent',
  props: {
    classes: {},
  },
}
</script>
