import {
  createResource,
  postResource,
  // getResource,
} from '@resource/resource'

import { AUTH } from './paths'

export const registrationResource = createResource(
  AUTH.REGISTRATION, postResource
)

export const authResource = createResource(
  AUTH.SIGNIN, postResource
)

export const resetPasswordResource = createResource(
  AUTH.RESET_PASSWORD, postResource
)

export const setNewPasswordResource = createResource(
  AUTH.SET_NEW_PASSWORD, postResource
)

export const changeEmailResource = createResource(
  AUTH.CHANGE_EMAIL, postResource
)

export const changePasswordResource = createResource(
  AUTH.CHANGE_PASSWORD, postResource
)

export const emailNotSetResource = createResource(
  AUTH.EMAIL_NOT_SET_FORM, postResource
)

export const logoutResource = createResource(
  AUTH.LOGOUT, postResource
)
