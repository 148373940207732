<template lang="pug">
  include ../mixins
  div
    vue-slider.ma--bottom_sm(
      v-model="rangeValue"
      :min="options[0].min"
      :max="options[0].max"
      :contained="true"
      :lazy="true"
      :order="false"
    )
    bem-text.ma--bottom_sm(
      v-if="isError"
      tag="p"
      color="red"
      size="xs"
    ) {{_('Введіть коректне значення')}}
    row(space="xs-xl")
      cell.ma--bottom_sm-till-xxl(cols="auto")
        row(align="center")
          cell(cols="auto")
            bem-text.ma--bottom_xs(
              tag="p"
              color="main"
              size="xs"
            ) {{_('от')}}
            +b.range__input.INPUT(
              type="number"
              v-model.number="rangeValue[0]"
              :placeholder="_('от')"
              :min="options[0].min"
              :max="options[0].max"
              @input="validateRange()"
            )
      cell(cols="auto")
        row(align="center")
          cell(cols="auto")
            bem-text.ma--bottom_xs(
              tag="p"
              color="main"
              size="xs"
            ) {{_('до')}}
            +b.range__input.INPUT(
              type="number"
              :min="options[0].min"
              :placeholder="_('до')"
              v-model.number="rangeValue[1]"
              :max="options[0].max"
              @input="validateRange()"
            )
      cell(cols="narrow")
        +b.filter__apply-button.de-text--size_xs.BEM-BUTTON(
          appearance="fill-accent-2"
          space="xs"
          rounded="sm"
          width="full"
          :disable="isError"
          @event="emitValue(); handleSelected(options[0].name)"
        ) {{_('OK')}}
</template>

<script>
// import VueSlider from 'vue-slider-component'
import { hasKey } from '@helpers/utils'

export default {
  name: 'range-control',
  components: {
    'vue-slider': () => import('vue-slider-component'),
  },
  props: {
    options: Array,
    paramsObj: Object,
    name: String,
    selectedArray: Array,
    type: String,
  },
  data() {
    return {
      rangeValue: this.options[0].value,
      params: this.paramsObj,
      selectedItems: this.selectedArray,
      currency: this._('грн'),
      isError: false,
    }
  },
  mounted() {
    this.normalize()
  },
  watch: {
    paramsObj: {
      handler(nval) {
        this.params = nval
        this.normalize()
      },
      deep: true,
    },
    selectedArray(nval) {
      this.selectedItems = nval
      this.normalize()
    },
    options: {
      handler(nval) {
        this.rangeValue = nval[0].value
      },
    },
  },
  methods: {
    validateRange() {
      if (!this.rangeValue[0] || !this.rangeValue[0] ||
        this.rangeValue[0] < this.options[0].min ||
        this.rangeValue[1] > this.options[0].max ||
        this.rangeValue[0] > this.rangeValue[1]
      ) {
        this.isError = true
      } else {
        this.isError = false
      }
    },
    emitValue() {
      if (!this.rangeValue[0] || !this.rangeValue[0] ||
        this.rangeValue[0] < this.options[0].min ||
        this.rangeValue[1] > this.options[0].max ||
        this.rangeValue[0] > this.rangeValue[1]
      ) {
        this.isError = true
      } else {
        this.isError = false
        if (this.options[0].min === this.rangeValue[0] && this.options[0].max === this.rangeValue[1]) {
          delete this.params[`${this.name}_min`]
          delete this.params[`${this.name}_max`]
          this.$emit('update-params', this.params)
        } else {
          this.params[`${this.name}_min`] = [this.rangeValue[0]]
          this.params[`${this.name}_max`] = [this.rangeValue[1]]
          this.$emit('update-params', this.params)
        }
      }
    },
    normalize() {
      if (hasKey(this.params, `${this.name}_min`)) {
        this.rangeValue = [
          Number(this.params[`${this.name}_min`][0]),
          Number(this.params[`${this.name}_max`][0]),
        ]
      } else {
        this.rangeValue = this.options[0].value
      }
    },
    handleSelected(label) {
      if (this.selectedItems) {
        const selectedData = [...this.selectedItems]
        const [min, max] = this.rangeValue
        if (
          this.options[0].min === min &&
          this.options[0].max === max
        ) {
          const index = selectedData.findIndex(el => el.label === label)
          if (-1 !== index) {
            selectedData.splice(index, 1)
            this.$emit('update-selected', selectedData)
          }
        } else {
          const index = selectedData.findIndex(el => el.label === label)
          if (-1 !== index) {
            this.$set(selectedData, index, {
              label,
              type: this.type,
              title: `${this.rangeValue[0]} ${this.currency} - ${this.rangeValue[1]} ${this.currency}`,
              name: this.name,
            })
            this.$emit('update-selected', selectedData)
          } else {
            selectedData.push({
              label,
              type: this.type,
              title: `${this.rangeValue[0]} ${this.currency} - ${this.rangeValue[1]} ${this.currency}`,
              name: this.name,
            })
            this.$emit('update-selected', selectedData)
          }
        }
      }
    },
  },
}
</script>
