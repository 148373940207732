<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_3sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Изменить Email')}}
    validation-observer(
      tag="form"
      autocomplete="off"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          autocomplete="new-password"
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Введите Ваш пароль')"
          :required="true"
          name="password"
        )
      +b.form.ma--bottom_sm
        control-input(
          autocomplete="false"
          rules="custom_email|required"
          type="email"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('Укажите новый Email')"
          v-model="formdata.newEmail"
          :required="true"
          name="newEmail"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.de-text--size_2xs.ma--top_sm(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disable="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Изменить E-mail')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { changeEmailResource } from '@services/auth.service.js'

export default {
  name: 'change-email-form',
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        password: '',
        newEmail: '',
      },
    }
  },
  methods: {
    send(data) {
      return changeEmailResource.execute({}, data)
        .then(res => {
          this.handleResponse({ res, modal: true })
        })
    },
  },
}
</script>
