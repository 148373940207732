import SuccessModal from '@app/Modals/SuccessModal'
import { mapGetters, mapActions } from 'vuex'
import { USER_DATA_REQUEST } from '@store/action-types'

const messageGetter = x => x.message

export function defaultValidatorErrorsParser(errors) {
  return Object.keys(errors).reduce((acc, x) => {
    if (Array.isArray(errors[x])) {
      acc[x] = errors[x].map(messageGetter)
    } else {
      acc[x] = [errors[x].message]
    }
    return acc
  }, {})
}

export default {
  data() {
    return {
      data: {},
      isLoad: false,
      fieldErrors: [],
      token: '',
    }
  },
  mounted() {
    if (this.isPrefillForm) this.userRequest()
  },
  methods: {
    catchFormErrors(promise) {
      return promise.catch(e => {
        const clientError = 400
        const serverError = 500
        if (!e.status || clientError > e.status || serverError <= e.status) {
          throw e
        }
        if (!this.updateValidator) {
          return e
        }
        return e.json().then(body => {
          const errors = {}
          body.errors.forEach(error => {
            if ('request' === error.domain && error.state) {
              Object.assign(errors, error.state)
            }
          })
          this.updateValidator(errors)
          return e
        })
      })
    },
    async submit(isValid, handler) {
      if (!isValid) {
        return Promise.reject()
      }
      this.isLoad = true
      return this.catchFormErrors(handler(this.formdata))
        .finally(() => {
          this.isLoad = false
        })
    },
    async validateBeforeSubmit(handler = this.send) {
      const isValid = await this.$refs.observer.validate()
      if (isValid) {
        await this.generateCaptcha(isValid, handler)
      } else {
        this.isLoad = false
        this.scrollToError()
      }
    },
    /* eslint-disable no-undef */
    async generateCaptcha(isValid, handler) {
      const self = this
      grecaptcha.ready(() => {
        grecaptcha.execute(window.sitekey, { action: 'submit' })
          .then(async token => {
            self.formdata.recaptcha = token
            await this.submit(isValid, handler)
          })
      })
    },
    /* eslint-enable no-undef */
    handleResponse(response) {
      const { redirect, modal, noFieldClearing = false } = response
      if (!noFieldClearing) {
        Object.keys(this.formdata).forEach(key => {
          this.formdata[key] = null
        })
      }
      if (modal) {
        const { res: { data: { item: { message: { title, text } } } } } = response
        this.$emit('close')
        this.showModal(
          title,
          text
        )
      }
      if (redirect) {
        const { res: { data: { item: { location } } } } = response
        window.location.replace(location)
      }
      this.$refs.observer.reset()
      this.fieldErrors = []
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors)
      this.$refs.observer.setErrors(parsed)
      this.fieldErrors = parsed.nonFieldErrors
    },
    showModal(title, text) {
      this.$modal.show(SuccessModal, {
        title,
        text,
      },
      {
        name: 'success-modal',
        classes: 'modal modal--container modal--size_sm modal--padding_sm',
      })
    },
    // prefill input fields with data
    fillFormdata(userData) {
      const obj = {}
      Object.keys(userData).forEach(key => {
        if ('phone' === key) obj[key] = userData[key] || '+38'
        else obj[key] = userData[key]
      })
      return obj
    },
    ...mapActions('cabinet', {
      userRequest: USER_DATA_REQUEST,
    }),
    scrollToError() {
      const errArray = document.getElementsByClassName('.control-hint')
      if (errArray.length) {
        errArray[0].scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
    },
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
