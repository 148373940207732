var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('button',{staticClass:"de-icon de-icon--type_close de-icon--size_md modal-close modal-close--common",attrs:{"tabindex":"-1"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('bem-text',{staticClass:"ma--bottom_3sm",attrs:{"tag":"p","weight":"bold","size":"2md","height":"xs"}},[_vm._v(_vm._s(_vm._('Подписка на новости')))]),_c('bem-text',{staticClass:"ma--bottom_lg",attrs:{"tag":"p","size":"2xs","height":"xs"}},[_vm._v(_vm._s(_vm._('Вы будете получать подборку полезной информации по интересующей вас теме')))]),_c('validation-observer',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('news-subscription-form-controller',{attrs:{"formdata":_vm.formdata},on:{"handle-response":function($event){return _vm.handleSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var categoriesList = ref.categoriesList;
var send = ref.send;
var isLoad = ref.isLoad;
var handleSelection = ref.handleSelection;
var checkCurrentOption = ref.checkCurrentOption;
return [_c('div',[_c('div',{staticClass:"form ma ma--bottom_sm"},[_c('control-input',{attrs:{"rules":"custom_email|required","type":"email","label-class":"form__label","class-name":"form__input","input-label":_vm._('E-mail'),"required":true,"name":"email"},model:{value:(_vm.formdata.email),callback:function ($$v) {_vm.$set(_vm.formdata, "email", $$v)},expression:"formdata.email"}})],1),_c('div',{staticClass:"common-multiselect common-multiselect--fullwidth form ma ma--bottom_sm-md"},[_c('control-multiselect',{attrs:{"options":categoriesList,"track-by":"id","label":"title","allow-empty":false,"label-class":"form__label","input-label":_vm._('Тема'),"placeholder":_vm._('Выберите тему'),"required":false,"multiple":true,"name":"category"},on:{"input":function($event){return handleSelection($event)},"select":function($event){return checkCurrentOption($event)}},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Елементи не знайдено")))])]},proxy:true},{key:"noOptions",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Список порожній")))])]},proxy:true}],null,true),model:{value:(_vm.formdata.category),callback:function ($$v) {_vm.$set(_vm.formdata, "category", $$v)},expression:"formdata.category"}})],1),_c('bem-button',{staticClass:"de-text--size_2xs",attrs:{"appearance":"fill-accent-1","space":"sm","width":"full","rounded":"sm","disable":isLoad},on:{"event":function($event){return _vm.validateBeforeSubmit(send)}}},[_vm._v(_vm._s(_vm._('Подписаться')))])],1)]}}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }