import SearchCatalog from './SearchCatalog'

const ProductCatalog = () => import('./ProductCatalog')
const PromoProductCatalog = () => import('./PromoCatalog')

export default function install(Vue) {
  Vue.component('ProductCatalog', ProductCatalog)
  Vue.component('PromoProductCatalog', PromoProductCatalog)
  Vue.component(SearchCatalog.name, SearchCatalog)
}
