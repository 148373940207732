<template lang="pug">
  include ../mixins
  row(justify="center")
    +b.language.A(
      v-for="lang in languagesList"
      :class="[{'is-active': lang.code == currentLang}, itemClass]"
      @click.prevent="handleLanguage(lang.code)"
    ) {{ lang.title }}
</template>

<script>
import { DEFAULT_LANGUAGE, removeLanguage, generateHref } from '@helpers/url'

export default {
  name: 'language-list',
  props: {
    itemClass: {
      type: String,
      default: 'language--common',
    },
  },
  data() {
    return {
      currentLang: window.language,
      languagesList: [
        { title: 'UA', code: 'uk' },
        { title: 'RU', code: 'ru' },
        { title: 'EN', code: 'en' },
      ],
    }
  },
  mounted() {
    this.compareLangPrefixes()
  },
  methods: {
    compareLangPrefixes() {
      const path = window.location.pathname
      const [, lang] = path.split('/')
      this.language = 2 === lang.length ? lang : DEFAULT_LANGUAGE
    },
    handleLanguage(selectedLang) {
      const urlObj = {
        origin: window.location.origin,
      }
      const location = window.location.pathname
      if (selectedLang !== DEFAULT_LANGUAGE) urlObj.lang = selectedLang
      else urlObj.lang = ''
      urlObj.path = removeLanguage(location)
      this.redirect(generateHref(urlObj, this))
    },
    redirect(url) {
      window.location.replace(decodeURIComponent(url))
    },
  },
}
</script>
