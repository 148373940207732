<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_3sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Изменить пароль')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.passwordOld"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Укажите старый пароль')"
          :required="true"
          name="passwordOld"
        )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Укажите новый пароль')"
          :required="true"
          name="password"
        )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|min:8|confirmed:password"
          type="password"
          v-model="formdata.passwordConfirm"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Укажите новый пароль еще раз')"
          :required="true"
          name="passwordConfirm"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.de-text--size_2xs.ma--top_sm(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disable="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Изменить пароль')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { changePasswordResource } from '@services/auth.service.js'

export default {
  name: 'change-password-form',
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        passwordOld: '',
        password: '',
        passwordConfirm: '',
      },
    }
  },
  methods: {
    send(data) {
      return changePasswordResource.execute({}, data)
        .then(res => {
          this.handleResponse({ res, modal: true })
        })
    },
  },
}
</script>
