<script>
import { logoutResource } from '@services/auth.service.js'
import { prefixLanguage } from '@helpers/url'

export default {
  name: 'log-out',
  props: {
    redirect: String,
  },
  methods: {
    send() {
      return logoutResource.execute({})
        .then(() => {
          window.location.replace(prefixLanguage('/'))
        })
    },
  },
  render() {
    return this.$scopedSlots.default({
      logout: this.send,
    })
  },
}
</script>
