<template lang="pug">
  include ../mixins
  div
    +b.de-card--color_white.--space_lg.ma--top_sm.--bottom_sm.--bottom_md-xl.position--relative
      row.ma--bottom_2sm
        cell(cols="12 6-lg 7-xl")
          slot(name="top-banner-text")
          faq-search-form.ma--top_3sm(
            :search-string="paramsObj.search"
            @search-value="paramsObj.search = $event; sendRequest()"
          )
          bem-button.ma--top_sm(
            v-if="paramsObj.search && paramsObj.search !== ''"
            block="de-link"
            color="accent-1"
            size="2xs"
            @event="clearSearch"
          ) {{_('Результаты поиска по')}} "{{ paramsObj.search}}" {{_('очистить')}}
      slot(name="top-banner-img")
    div.position--relative
      ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
      div(@click="listenCategory($event)" v-if="isResponse === false")
        slot(name="faq-block")
      div(@click="listenCategory($event)" v-if="isResponse === true")
        renderer(
          :result="response"
        )
</template>

<script>
import { faqListResource } from '@services/common.service.js'
import { prefixLanguage } from '@helpers/url'

export default {
  name: 'faq-component',
  components: {
    'faq-search-form': () => import('@app/Forms/FaqSearchForm'),
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
  },
  props: {
    slug: String,
  },
  data() {
    return {
      paramsObj: {},
      isResponse: false,
      response: '',
      isLoad: false,
    }
  },
  mounted() {
    this.parseFriendlyUrl()
  },
  methods: {
    listenCategory(event) {
      if (event.target.dataset.category) {
        this.paramsObj.category = event.target.dataset.category
        this.sendRequest()
      }
    },
    sendRequest() {
      this.isLoad = true
      this.removeEmpty(this.paramsObj)
      return faqListResource.execute({
        filters: this.paramsObj,
      })
        .then(({ data }) => {
          this.response = data
          this.isResponse = true
          this.isLoad = false
          this.pushHistory()
        })
    },
    pushHistory() {
      const baseUrl = `${prefixLanguage(window.location.origin)}/`
      window.history.pushState(null, null, `${baseUrl}${this.slug}${this.generateParams(this.paramsObj)}`)
    },
    generateParams(params) {
      let paramsString = ''
      Object.keys(params).forEach(key => {
        paramsString += `${key}=${params[key]};`
      })
      if ('' !== paramsString) paramsString += '/'
      return paramsString
    },
    parseFriendlyUrl() {
      const path = window.location.href
      const params = path.split(this.slug)[1]
      if (0 < params.length) {
        const paramsList = params.split(';')
        paramsList.forEach(el => {
          const [objKey, objValue] = el.split('=')
          if (objValue && 0 < objValue.length) this.paramsObj[objKey] = objValue
          if ('search' === objKey) this.paramsObj[objKey] = decodeURIComponent(objValue)
        })
      }
    },
    removeEmpty(item) {
      Object.keys(item).forEach(el => {
        if (0 === item[el].length || '' === item[el][0]) delete item[el]
      })
      return item
    },
    clearSearch() {
      delete this.paramsObj.search
      this.sendRequest()
    },
  },
}
</script>
