import ModalHandler from './ModalHandler'
import CommonModal from './CommonModal'
import LiveBookModal from './LiveBookModal'
import Video from './Video'

export default function install(Vue) {
  Vue.component(ModalHandler.name, ModalHandler)
  Vue.component(CommonModal.name, CommonModal)
  Vue.component(LiveBookModal.name, LiveBookModal)
  Vue.component(Video.name, Video)
}
