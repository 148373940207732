// ios scroll
const body = document.querySelector('body')
let scrollPosition = 0
const zero = 0

export const scrollUnlock = () => {
  // body.style.removeProperty('top')
  body.classList.remove('scroll-lock')
  body.classList.add('scroll-unlock')
  window.scrollTo(zero, scrollPosition)
}

export const scrollLock = () => {
  scrollPosition = window.pageYOffset
  // body.style.top = `-${scrollPosition}px`
  body.classList.add('scroll-lock')
  body.classList.remove('scroll-unlock')
}

export const scrollToContent = element => {
  const header = document.querySelector('.js-fixed-header')
  const headerHeight = header.clientHeight

  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offset = 20
  const offsetPosition = elementPosition - headerHeight - offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}
