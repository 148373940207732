<template lang="pug">
  include ../mixins
  div
    row.position--relative(space="2sm-xl" align="start")
      ui-preloader(v-if="isLoad" loader-color='loader-wrapper__bg--light')
      cell.position--relative(cols="12 3-xl 2-xxl")
        slot(name="title")
        ui-preloader(v-if="isLoadFilters" loader-color='loader-wrapper__bg--light')
        +b.de-card--color_white.--space_sm.--variant_fullheight.ma--bottom_3sm.filter-wrapper(
          v-if="isFiltersResponse"
          :class="{'is-active': isShowFilters == true}"
        )
          +b.filter-close.u-display--hidden-xl.BUTTON(@click="toggleFilter")
            bem-icon.z-index--2(
              type="close"
              size="3sm"
              color="main"
              variant="right"
              @click="toggleFilter"
            )
          filter-section(
            v-if="filters.length > 0"
            :filters="filters"
            :params-obj="paramsObj"
            @update-params="paramsObj = $event; getCards(); isAddPage = false"
            @clear-filters="clearFilters()"
          )
          +b.filter-wrapper__button.de-card--color_white.--space_2xs.u-display--hidden-xl.filter-apply(:class="{'is-active': isShowFilters == true}")
            row(justify="center")
              cell(cols="12 8-sm 4-md")
                +b.de-card--space_xs
                  bem-button(
                    appearance="border-accent-1"
                    width="full"
                    space="sm"
                    rounded="lg"
                    @event="toggleFilter()"
                  ) {{_('Застосувати')}}
      +b.g-cell.g-cols--12.--9-xl.--10-xxl(ref="cards")
        bem-text.ma--bottom_3sm(
          tag="p"
          weight="light"
          size="md"
          height="xs"
        ) {{_('Найдено')}} {{ amount }} &nbsp;
          span.de-text--weight_light(v-word-case:goods="amount" :key="amount")
        +b.filter-btn.ma--center.u-display--hidden-xl.ma--bottom_sm.ma--top_sm.BUTTON(
          @click="toggleFilter()"
          ref="filterButton"
        ) {{_('Фильтры')}}
        //- bem-text.ma--bottom_3sm(
        //-   tag="p"
        //-   weight="light"
        //-   size="md"
        //-   height="xs"
        //- ) {{_('Найдено')}} {{ amount }} &nbsp;
        //-   span.de-text--weight_light(v-word-case:goods="amount" :key="amount")
        div(:style="{ minHeight: initialHeight+'px' }")
          template(v-if="!isCardsResponse")
            slot(name="products")
          row.ma--bottom_sm.position--relative(space="sm-xl" v-if="isCardsResponse")
            +b.custom-width.ma--bottom_2xs.--bottom_sm-xl.CELL(
              v-for="(product, index) in cardsResponse"
              :key="index"
            )
              product-card(
                :product-data="product"
                :object-item="objectItem"
                list-key="Search results"
              )
        pagination.ma--top_md(
          v-if="count > perPage"
          class-wrapper="pagination__wrapper--light"
          :is-scroll="true"
          :scroll-element="$refs.cards"
          :current='page'
          :total='count'
          :perPage='perPage'
          :is-add-more="true"
          @add="isAddPage = $event"
          @page-changed="page = $event; getCards($event); scrollByPagination()"
        )
</template>

<script>
import {
  searchFiltersResource,
  searchCardsResource,
} from '@services/search.service.js'
import CatalogMixin from '@mixins/catalog.mixin.js'
import { scrollToContent } from '@helpers/scroll'

const one = 1

export default {
  name: 'search-catalog',
  mixins: [CatalogMixin],
  components: {
    'filter-section': () => import('./FilterSection'),
    'filter-selected': () => import('./FilterSelected'),
    'product-card': () => import('@app/Cards/ProductCard'),
    'ui-preloader': () => import('@app/UiElements/UiPreloader'),
    pagination: () => import('@app/UiElements/Pagination'),
  },
  props: {
    initialAmount: Number,
    pagination: Object,
    objectItem: Object,
  },
  data() {
    return {
      paramsObj: {},
      filters: [],
      count: this.pagination.count,
      page: this.pagination.page,
      perPage: this.pagination.perPage,
      slug: 'search',
      amount: this.initialAmount,
      isLoadFilters: false,
      isFiltersResponse: false,
      isCardsResponse: false,
      cardsResponse: [],
      isAddPage: false,
      isFirstAdd: true,
      isLoad: false,
    }
  },
  async mounted() {
    await this.checkUrl(this.slug)
    await this.prepareFilterData()
    await this.getCardsBlockHeight()
    await this.getFilters()
  },
  methods: {
    async getCards(page = one) {
      await this.formatDataBeforeRequest()
      this.isLoad = true
      return searchCardsResource.execute({
        filters: this.paramsObj,
        page,
      })
        .then(async res => {
          await this.handleCombinePagination(res)
          await this.handleCards(res.meta)
          await this.pushHistory()
          this.isLoad = false
        })
    },
    getFilters() {
      this.isLoadFilters = true
      const searchParams = {
        search: this.paramsObj.search,
      }
      return searchFiltersResource.execute({
        filters: searchParams,
        page: this.page,
      })
        .then(({ data: { items } }) => {
          this.filters = items
          this.isLoadFilters = false
          this.isFiltersResponse = true
        })
    },
    async formatDataBeforeRequest() {
      if (
        true === this.isFirstAdd &&
        true === this.isAddPage &&
        1 !== this.page
      ) await this.getFirstCards(this.page - 1)
      this.paramsObj = this.removeEmpty({ ...this.paramsObj })
    },
    getFirstCards(page = one) {
      return searchCardsResource.execute({
        filters: this.paramsObj,
        page,
      })
        .then(async ({ data }) => {
          this.firstCards = data
        })
    },
    clearFilters() {
      Object.keys(this.paramsObj).forEach(key => {
        if ('search' !== key) delete this.paramsObj[key]
      })
      this.isAddPage = false
      this.isFirstAdd = true
      this.getCards()
    },
    scrollByPagination() {
      if (false === this.isAddPage) {
        const element = this.$refs.cards
        scrollToContent(element)
      }
    },
  },
}
</script>
