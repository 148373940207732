<script>
// import {CommonModal} from './CommonModal'

export default {
  name: 'modal-handler',
  props: {
    componentName: {
      type: String,
      default: 'empty-component',
    },
    objectItem: {
      required: false,
    },
    modalClass: {
      type: String,
      default: 'modal--size_sm',
    },
    afterModalRequest: {
      type: Function,
    },
  },
  render() {
    return this.$scopedSlots.default({
      handler: this.toggleModalHandler,
    })
  },
  methods: {
    toggleModalHandler() {
      if (!this.componentName) return
      // const body = document.getElementById('body')
      // body.classList.add('disable-scroll')
      this.$modal.show(() => import('./CommonModal'), {
        component: this.componentName,
        objectItem: this.objectItem,
        afterModalRequest: this.afterModalRequest,
      }, {
        classes: `modal modal--container ${this.modalClass}`,
        name: 'modal',
      })
      // const modal = document.getElementById('modals-container')
      // console.log('modal', modal)
      // const [modalOverlay] = modal.getElementsByClassName('vm--container')
      // console.log('modalOverlay', modalOverlay)
      // modalOverlay.addEventListener('click', () => {
      //   console.log('modalOverlay click!!!')
      //   const body = document.getElementById('body')
      //   body.classList.remove('disable-scroll')
      // }, { passive: true })
    },
  },
}
</script>
