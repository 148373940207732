import { cartInfoResource } from '@services/order.service.js'
import { CHANGE_ACTION, CART_COUNT_REQUEST } from '@store/action-types'
import { SET_ACTION_COUNT } from '@store/mutation-types'

export const common = {
  namespaced: true,
  state: {
    actionsCount: {
      cart: {},
      favorite: '',
      compare: '',
      configurator: '',
    },
  },
  getters: {
    getCartData: state => state.actionsCount.cart,
    getActionsData: state => state.actionsCount,
  },
  actions: {
    [CHANGE_ACTION](context, payload) {
      context.commit(SET_ACTION_COUNT, payload)
    },
    [CART_COUNT_REQUEST]({ commit }, key) {
      return cartInfoResource.execute()
        .then(res => { commit(SET_ACTION_COUNT, { key, res: res.data.item }) })
    },
  },
  mutations: {
    [SET_ACTION_COUNT](state, payload) {
      state.actionsCount[payload.key] = payload.res
    },
  },
}
