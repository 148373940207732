/* eslint-disable */
import Masonry from 'masonry-layout'

export function initGrid() {
  const [elem] = document.getElementsByClassName('gallery')

  if (elem) {
    new Masonry(elem, {
      itemSelector: '.gallery-item',
      percentPosition: true,
    })
  }
}
