import { prefixLanguage } from '@helpers/url'

export const BASE_LINK = prefixLanguage('/cabinet/')
const cabinetUrl = path => BASE_LINK + path

export const USER_BASE_PATH = BASE_LINK
export const USER_ORDERS_PATH = cabinetUrl('order/list/')
export const USER_SETTINGS_PATH = cabinetUrl('settings/')
export const USER_TRACKED_PATH = cabinetUrl('tracked/')
export const USER_BONUSES_PATH = cabinetUrl('bonuses/')
