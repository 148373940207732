<template lang="pug">
  include ../mixins
  div
    bem-text.ma--bottom_3sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Установите новый пароль')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|min:8"
          ref='password'
          type="password"
          v-model="formdata.password"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Пароль')"
          :required="true"
          name="password"
        )
      +b.form.ma--bottom_sm
        control-input(
          rules="required|min:8|confirmed:password"
          type="password"
          v-model="formdata.passwordConfirm"
          class-name="form__input form__input--password"
          label-class="form__label"
          :input-label="_('Повторите пароль')"
          :required="true"
          name="passwordConfirm"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.de-text--size_2xs(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disabled="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Войти')}}
</template>

<script>
import { setNewPasswordResource } from '@services/auth.service.js'
import FormMixin from '@mixins/form.mixin.js'

export default {
  name: 'set-new-password-form',
  mixins: [FormMixin],
  props: {
    idCript: String,
    userToken: String,
    redirectLink: String,
  },
  data() {
    return {
      formdata: {
        password: '',
        passwordConfirm: '',
      },
    }
  },
  methods: {
    send(data) {
      data.idCript = this.idCript
      data.userToken = this.userToken
      return setNewPasswordResource.execute({}, data)
        .then(() => {
          window.location.replace(this.redirectLink)
        })
    },
  },
}
</script>
