<script>
import { cartChangeResource } from '@services/order.service.js'
import { cartData } from '@mixins/order.mixin.js'
import { mapActions } from 'vuex'
import { CART_COUNT_REQUEST } from '@store/action-types'

export default {
  name: 'buy-action',
  props: {
    productId: [Number, Array],
    objectItem: Object,
  },
  data() {
    return {
      id: this.productId,
    }
  },
  methods: {
    addToCart() {
      // if we need to add several products to cart
      if (!Array.isArray(this.id)) this.id = [this.id]
      return cartChangeResource.execute({}, cartData(this.id, this.objectItem?.amount || 1))
        .then(res => {
          const { data: { item: { products } } } = res
          const addedProduct = products.find(el => el.id === this.productId)
          this.addDatalayerEvent([addedProduct])
          this.cartCountRequest('cart')
          // open cart modal after product adding
          this.openModal()
        })
    },
    openModal() {
      this.$modal.show(() => import('@app/Modals/CommonModal'), {
        component: 'cart-modal',
        objectItem: this.objectItem,
      },
      {
        name: 'modal',
        classes: 'modal modal--size_custom-1 modal--container modal--padding_sm',
      })
    },
    ...mapActions('common', {
      cartCountRequest: CART_COUNT_REQUEST,
    }),
    addDatalayerEvent(products) {
      const preparedProducts = products.map(el => {
        el = {
          name: el.title,
          id: el.isbn,
          price: el.price,
          quantity: el.amount,
          category: el.categoryTitle,
        }
        return el
      })
      // eslint-disable-next-line
      dataLayer.push({
        ecommerce: {
          currencyCode: 'UAH',
          add: {
            products: preparedProducts,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
        'gtm-ee-event-non-interaction': 'False',
      })
      this.addDatalayerCheckoutEvent(preparedProducts)
    },
    addDatalayerCheckoutEvent(products) {
      // eslint-disable-next-line
      dataLayer.push({
        ecommerce: {
          currencyCode: 'UAH',
          checkout: {
            actionField: { step: 1 },
            products,
          },
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Checkout Step 1',
        'gtm-ee-event-non-interaction': 'False',
      })
    },
  },
  render() {
    return this.$scopedSlots.default({
      add: this.addToCart,
    })
  },
}
</script>
