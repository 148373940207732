import NewsSubscriptionForm from './NewsSubscriptionForm'
import SetNewPasswordForm from './SetNewPasswordForm'
import ChangeEmailForm from './ChangeEmailForm'
import ChangePasswordForm from './ChangePasswordForm'
import SetEmailForm from './SetEmailForm'
import FaqForm from './FaqForm'
import NewsSubscriptionFormModal from './NewsSubscriptionFormModal'
import NewsSubscriptionFormPage from './NewsSubscriptionFormPage'
import TrackedForm from './TrackedForm'
import SetLocationForm from './SetLocationForm'
import OneClickBuyForm from './OneClickBuyForm'
import WholesaleForm from './WholesaleForm'

const RegistrationForm = () => import('./RegistrationForm')
const AuthForm = () => import('./AuthForm')
const ContactFormModal = () => import('./ContactFormModal')
const ContactForm = () => import('./ContactForm')
const PromoSubscriptionFormPage = () => import('./PromoSubscriptionFormPage')

export default function install(Vue) {
  Vue.component(NewsSubscriptionForm.name, NewsSubscriptionForm)
  Vue.component('RegistrationForm', RegistrationForm)
  Vue.component('AuthForm', AuthForm)
  Vue.component(SetNewPasswordForm.name, SetNewPasswordForm)
  Vue.component(ChangeEmailForm.name, ChangeEmailForm)
  Vue.component(ChangePasswordForm.name, ChangePasswordForm)
  Vue.component(SetEmailForm.name, SetEmailForm)
  Vue.component(FaqForm.name, FaqForm)
  Vue.component('ContactForm', ContactForm)
  Vue.component('ContactFormModal', ContactFormModal)
  Vue.component(NewsSubscriptionFormModal.name, NewsSubscriptionFormModal)
  Vue.component(NewsSubscriptionFormPage.name, NewsSubscriptionFormPage)
  Vue.component(TrackedForm.name, TrackedForm)
  Vue.component(SetLocationForm.name, SetLocationForm)
  Vue.component(OneClickBuyForm.name, OneClickBuyForm)
  Vue.component(WholesaleForm.name, WholesaleForm)
  Vue.component('PromoSubscriptionFormPage', PromoSubscriptionFormPage)
}
