import {
  createResource,
  postResource,
  getResource,
} from '@resource/resource'

import { ORDER } from './paths'

export const cartListResource = createResource(
  ORDER.CART_LIST, getResource
)

export const cartChangeResource = createResource(
  ORDER.CART_CHANGE, postResource
)

export const cartInfoResource = createResource(
  ORDER.CART_INFO, getResource
)

export const cartClearResource = createResource(
  ORDER.CART_CLEAR, postResource
)

export const orderInitialResource = createResource(
  ORDER.ORDER_INTIAL_DATA, getResource
)

export const paymentListResource = createResource(
  ORDER.PAYMENT_LIST, postResource
)

export const orderCreateResource = createResource(
  ORDER.ORDER_CREATE, postResource
)

export const oneClickOrderResource = createResource(
  ORDER.ONE_CLICK, postResource
)

export const promocodeResource = createResource(
  ORDER.PROMOCODE, postResource
)

export const presentsResource = createResource(
  ORDER.PRESENTS, postResource
)
