<template lang="pug">
  component(
    :is="tag"
    :class="genetatedClass"
  )
    slot
</template>

<script>
import bemMixin from './bem-mixin'

export default {
  name: 'bem-text',
  mixins: [
    bemMixin,
  ],
  props: {
    block: {
      default: () => 'de-text',
    },
  },
}
</script>
