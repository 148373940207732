import {
  createResource,
  postResource,
  getResource,
} from '@resource/resource'

import { SEARCH } from './paths'

export const headerSearchResource = createResource(
  SEARCH.HEADER_SEARCH, postResource
)

export const searchFiltersResource = createResource(
  SEARCH.SEARCH_CATALOG_FILTERS, getResource
)

export const searchCardsResource = createResource(
  SEARCH.SEARCH_CARDS, getResource
)
