import './api'
import './middleware'
import './resource'
import './transformer'

import Components from './components'

function install(Vue) {
  Vue.use(Components)
}

export * from './resource'
export default {
  install,
}
