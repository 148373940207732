<template lang="pug">
  include ../mixins
  div
    +b.g-row.--appearance_nowrap.--align_center.ma--bottom_2xs(
      v-for="option in options"
      :key="option.label"
    )
      +b.g-cell.g-cols.--narrow
        input(
          :type="type"
          v-model='checkedItems'
          :id="option.label"
          :name="option.name"
          :value="option.label"
          @change="emitValue(); handleSelected(option.name, option.title, option.label)"
        )
      +b.g-cell.g-cols.--auto
        label.pointer(:for="option.label") {{ option.title }}
</template>

<script>
import { hasKey } from '@helpers/utils'

export default {
  name: 'checkbox-control',
  props: {
    options: Array,
    type: String,
    paramsObj: Object,
    name: String,
    selectedArray: Array,
    isSelectedBlock: Boolean,
  },
  data() {
    return {
      checkedItems: [],
      selectedItems: this.selectedArray,
      params: this.paramsObj,
      isSelected: this.isSelectedBlock,
    }
  },
  watch: {
    paramsObj: {
      handler(nval) {
        this.params = nval
        this.normalize(this.params)
      },
      deep: true,
    },
    selectedArray(nval) {
      this.selectedItems = nval
    },
  },
  mounted() {
    this.normalize(this.params)
  },
  methods: {
    normalize(val) {
      this.checkedItems = hasKey(val, this.name)
        ? val[this.name]
        : []
    },
    emitValue() {
      this.params[this.name] = this.checkedItems
      this.$emit('update-params', this.params)
    },
    handleSelected(name, title, label) {
      if (this.selectedArray) {
        const data = [...this.selectedItems]
        if (true === this.isSelected) {
          const index = data.findIndex(el => el.label === label)
          if (-1 !== index) {
            data.splice(index, 1)
            this.$emit('update-selected', data)
          } else {
            data.push({ title, label, name })
            this.$emit('update-selected', data)
          }
        }
      }
    },
  },
}
</script>
