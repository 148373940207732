import {
  USER_BASE_PATH,
  USER_ORDERS_PATH,
  USER_SETTINGS_PATH,
  USER_TRACKED_PATH,
  USER_BONUSES_PATH,
} from '@router/router-path'

export const routes = [
  {
    path: USER_BASE_PATH,
    name: 'user-base',
    component: () => import('@app/Cabinet/CabinetOrigin'),
    redirect: () => USER_SETTINGS_PATH,
    // redirect: { name: USER_SETTINGS_PATH },
    // redirect: { name: 'user-settings' },
    children: [
      // {
      //   path: '',
      //   redirect: () => USER_SETTINGS_PATH
      // },
      {
        path: USER_ORDERS_PATH,
        component: () => import('@app/Cabinet/CabinetOrdersList'),
        name: 'user-orders',
      },
      {
        path: USER_SETTINGS_PATH,
        component: () => import('@app/Cabinet/CabinetSettings'),
        name: 'user-settings',
      },
      {
        path: USER_TRACKED_PATH,
        component: () => import('@app/Cabinet/CabinetTracked'),
        name: 'user-tracked',
      },
      {
        path: USER_BONUSES_PATH,
        component: () => import('@app/Cabinet/CabinetBonuses'),
        name: 'user-bonuses',
      },
    ],
  },
  {
    path: '*',
    component: () => import('@/js/components/Cabinet/NotFound'),
  },
]
