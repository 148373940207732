import { scrollUnlock, scrollLock } from '@helpers/scroll'

export function mobileMenuToggler() {
  const [menu] = document.getElementsByClassName('js-sidebar')
  const [toggler] = document.getElementsByClassName('js-toggler')
  const [close] = document.getElementsByClassName('js-close')
  function checkSidebarActivity() {
    if (menu.classList.contains('is-active')) scrollLock()
    else scrollUnlock()
  }
  function openMenu() {
    menu.classList.toggle('is-active')
    toggler.classList.toggle('is-active')
    checkSidebarActivity()
  }
  function closeMenu() {
    menu.classList.remove('is-active')
    toggler.classList.toggle('is-active')
    checkSidebarActivity()
  }
  if (menu) {
    toggler.addEventListener('click', openMenu)
    close.addEventListener('click', closeMenu)
  }
}
