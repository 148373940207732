const one = 1
export function cartData(id, quantity = one) {
  const obj = {}
  const entities = []
  id.forEach(element => {
    entities.push({
      element: {
        id: element,
        type: 'products.product',
      },
      quantity,
    })
  })
  obj.entities = entities
  return obj
}
