<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    news-subscription-form-controller(
      :formdata="formdata"
      @handle-response="handleSubmit($event)"
    )
      template(#default="{ categoriesList, send, isLoad, handleSelection, checkCurrentOption }")
        row(align="end" space="sm" appearance="spaced")
          cell(cols="12 6-md auto-lg")
            +b.news-subscription
              control-input(
                rules="custom_email|required"
                type="email"
                label-class="form__label"
                :placeholder="_('Введите имейл')"
                :input-label="_('E-mail')"
                v-model="formdata.email"
                class-name="news-subscription__input"
                :required="true"
                name="email"
              )
              +e.icon.--variant_1.de-icon--type_mail.--size_sm.--color_accent-1.I
          cell(cols="12 6-md auto-lg")
            +b.common-multiselect--fullwidth.--white.form
              control-multiselect(
                :options="categoriesList"
                track-by="id"
                label="title"
                :allow-empty="true"
                label-class="form__label"
                :input-label="_('Тема')"
                v-model="formdata.category"
                :placeholder="_('Выберите тему')"
                :required="false"
                @input="handleSelection($event)"
                @select="checkCurrentOption($event)"
                :multiple="true"
                name="category"
              )
                template(#noResult)
                  span {{ _("Елементи не знайдено") }}
                template(#noOptions)
                  span {{ _("Список порожній") }}
                template(
                  slot="selection"
                  slot-scope="{ values, search }"
                  v-if="values.length"
                )
                  +b.SPAN.multiselect__single
                    | {{ values.map(el => el.title).join(', ') }}
                  +b.i-count {{ values.length }}
          cell(cols="12 narrow-lg")
            +e.button.de-text--size_2xs.BEM-BUTTON.subs-btn(
              appearance="fill-accent-1"
              rounded="sm"
              width="md"
              :disable="isLoad"
              @event="validateBeforeSubmit(send)"
            ) {{_('Подписаться')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'

export default {
  name: 'news-subscription-form-page',
  mixins: [FormMixin],
  components: {
    'news-subscription-form-controller': () => import('./NewsSubscriptionController.vue'),
  },
  data() {
    return {
      formdata: {
        email: '',
        category: null,
      },
    }
  },
  methods: {
    handleSubmit(res) {
      this.handleResponse({ modal: true, res })
    },
  },
}
</script>
