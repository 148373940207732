import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  // localize,
} from 'vee-validate'
// import { dictionary } from './constants'

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// const locale = document.documentElement.lang

// localize(locale, dictionary[locale])
