import { userSettingsResource } from '@services/cabinet.service.js'
import { USER_DATA_REQUEST } from '@store/action-types'
import { SET_USER_DATA } from '@store/mutation-types'

export const cabinet = {
  namespaced: true,
  state: {
    userData: {},
  },
  getters: {
    getUserData: state => state.userData,
  },
  actions: {
    [USER_DATA_REQUEST]({ commit }) {
      return userSettingsResource.execute()
        .then(res => { commit(SET_USER_DATA, res.data.item) })
    },
  },
  mutations: {
    [SET_USER_DATA](state, payload) {
      state.userData = payload
    },
  },
}
