import { debounce } from 'lodash'
import { createI18n } from '@/i18n'
import { createStore } from './js/store'
import { createRouter } from './js/router'

export function createApp({ Vue, I18n, Store, Router }) {
  const router = createRouter({ Vue, Router })
  const i18n = createI18n({ Vue, I18n })
  const store = createStore({ Vue, Store })
  const app = new Vue({
    i18n,
    store,
    router,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
        impressions: [],
        debounceDatalayerFn: null,
      }
    },
    created() {
      const timeout = 300
      this.debounceDatalayerFn = debounce(this.updateDatalayer, timeout)
    },
    methods: {
      updateImpressions(product) {
        this.impressions.push(product)
        this.debounceDatalayerFn()
      },
      updateDatalayer() {
        window.dataLayer.push({
          ecommerce: {
            currencyCode: 'UAH',
            impressions: this.impressions,
          },
          event: 'gtm-ee-event',
          'gtm-ee-event-category': 'Enhanced Ecommerce',
          'gtm-ee-event-action': 'Product Impressions',
          'gtm-ee-event-non-interaction': 'True',
        })

        this.updateGtag()

        this.impressions = []
      },

      updateGtag() {
        const { pagetype } = window

        if (pagetype) {
          const arrayOfProductsId = this.impressions.map(el => el.id)
          gtag('event', 'page_view', {
            send_to: 'AW-10800927469',
            ecomm_prodid: arrayOfProductsId,
            ecomm_pagetype: pagetype,
            ecomm_totalvalue: 0,
          })
        }
      },
    },
  })
  router.beforeEach((to, from, next) => {
    next()
    window.scrollTo(0, 0)
  })
  return { app, i18n, store, router }
}
