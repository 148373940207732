import { scrollUnlock, scrollLock } from '@helpers/scroll'
import { addLang } from '@helpers/url'
import { restoreObject, hasKey } from '@helpers/utils'

export default {
  data() {
    return {
      isShowFilters: false,
      isLoad: false,
      filters: [],
      initialHeight: 0,
    }
  },
  async mounted() {
    this.setScreenWidth()
    window.addEventListener('resize', () => {
      this.setScreenWidth()
    }, { passive: true })
  },
  methods: {
    /* set isOpen key and open filter sections
    after url parsing */
    prepareFilterData() {
      this.filters = this.filters.map(el => {
        el.options.map(item => {
          item.name = el.label
          if ('range-component' !== el.type) this.$set(item, 'checked', true)
          return item
        })
        return el
      })
      // open filter sections
      Object.keys(this.paramsObj).forEach(el => {
        this.filters.forEach(item => {
          if (el.split('_')[0] === item.label) {
            item.isOpen = true
          }
        })
      })
      this.showFilters = true
    },
    setScreenWidth() {
      if (window.matchMedia('(min-width: 1200px)').matches) {
        this.isShowFilters = false
      }
    },
    toggleFilter() {
      this.isShowFilters = !this.isShowFilters
      if (this.isShowFilters) {
        scrollLock()
      } else {
        scrollUnlock()
      }
    },
    pushHistory() {
      let baseUrl = `${addLang(window.location.origin)}/`
      if (this.slug) baseUrl += `${this.slug}/`
      if (this.category) baseUrl += `${this.category}/`
      let page
      if (1 < this.page) {
        page = `page/${this.page}/`
      } else {
        page = ''
      }
      window.history.pushState(null, null, `${baseUrl}${this.generateUrl(this.paramsObj)}${page}`)
    },
    handleCards(meta) {
      this.isCardsResponse = true
      this.page = meta.page
      this.count = meta.count
      this.perPage = meta.perPage
      this.amount = meta.count
    },
    generateUrl(params) {
      let url = ''
      Object.keys(params).forEach((key, i) => {
        if (0 === i) {
          url += `${key}=${'search' === key ? params[key] : this.getStringFromArray(params[key])}`
        } else {
          url += `;${key}=${'search' === key ? params[key] : this.getStringFromArray(params[key])}`
        }
      })
      if ('' !== url) {
        url = `${url}/`
      }
      return url
    },
    removeEmpty(item) {
      Object.keys(item).forEach(el => {
        if (0 === item[el].length || '' === item[el][0]) delete item[el]
      })
      return item
    },
    getStringFromArray(arr) {
      let params = ''
      arr.forEach((el, i) => {
        if (i !== arr.length - 1) {
          params += `${el},`
        } else {
          params += `${el}`
        }
      })
      return params
    },
    checkUrl(slug) {
      const base = slug
      const pathElem = window.location.pathname.split('/')
      const params = 'page' !== pathElem[pathElem.indexOf(base) + 1] ? pathElem[pathElem.indexOf(base) + 1] : ''
      if ('' !== params) {
        this.parseUrl(params)
      }
    },
    parseUrl(params) {
      const paramsString = params
      const paramsArray = paramsString.split(';')
      const obj = {}
      paramsArray.forEach(el => {
        const valuesArray = el.split('=')[1].split(',')
        obj[el.split('=')[0]] = valuesArray
        if ('true' === valuesArray) obj[el.split('=')[0]] = valuesArray
        // collect selected items for checked section
        this.filters.forEach(item => {
          item.options.forEach(option => {
            valuesArray.forEach(value => {
              if (
                option.label === value &&
                this.selectedArray
              ) this.selectedArray.push(option)
            })
          })
        })
      })
      if (
        hasKey(obj, 'price_min') &&
        hasKey(obj, 'price_min') &&
        this.selectedArray
      ) {
        this.selectedArray.push({
          title: `${[obj.price_min]} ${this._('грн')} - ${[obj.price_max]} ${this._('грн')}`,
          label: 'price',
          name: 'price',
          type: 'range',
        })
      }
      if (obj.search) obj.search = decodeURI(obj.search)
      this.paramsObj = obj
      if (this.paramsObj.sort) this.sortValue = restoreObject(this.sortArray, 'value', this.paramsObj.sort[0])
    },
    handleCombinePagination(res) {
      if (true === this.isAddPage) {
        if (true === this.isFirstAdd) this.cardsResponse = this.firstCards
        res.data.forEach(el => { this.cardsResponse.push(el) })
        this.initialHeight = 0
        this.isFirstAdd = false
      } else {
        this.initialHeight = 0
        this.isFirstAdd = false
        this.cardsResponse = res.data
        this.firstCards = ''
      }
    },
    getCardsBlockHeight() {
      this.initialHeight = this.$refs.cards.clientHeight
    },
  },
}
