<template lang="pug">
  include ../mixins
  validation-observer(
    tag="form"
    ref="observer"
    v-slot="{ errors }"
    @submit.prevent="validateBeforeSubmit"
  )
    row(space="3sm-xl")
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            rules="username|required"
            type="text"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Ваше имя')"
            v-model="formdata.name"
            :required="true"
            name="name"
          )
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm.position--relative
          control-input(
            rules="required|phone_length"
            type="tel"
            inputmode="tel"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Номер телефона')"
            v-model="formdata.phone"
            placeholder="+38"
            v-mask="'+38(###)### ## ##'"
            :required="true"
            name="phone"
          )
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            rules="custom_email|required"
            type="email"
            inputmode="email"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('E-mail')"
            v-model="formdata.email"
            :required="true"
            name="email"
          )
      cell(cols="12 6-md")
        +b.form.ma--bottom_sm
          control-input(
            type="text"
            label-class="form__label"
            class-name="form__input"
            :input-label="_('Тема')"
            v-model="formdata.subject"
            :required="false"
            name="subject"
          )
      cell(cols="12")
        +b.form.ma--bottom_sm
          control-textarea(
            rules="required"
            type="textarea"
            label-class="form__label"
            class-name="form__textarea form__textarea--md"
            :input-label="_('Расскажите о своей проблеме. Чем больше деталей, тем лучше')"
            v-model="formdata.question"
            :required="true"
            name="question"
          )
    row(justify="end")
      cell(cols="12 6-sm 4-lg 2-xxl")
        bem-button.de-text--size_2xs(
          appearance="fill-accent-1"
          space="sm"
          width="full"
          rounded="sm"
          :disabled="isLoad"
          @event="validateBeforeSubmit()"
        ) {{_('Отправить')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { mask } from 'vue-the-mask'
import { faqFormResource } from '@services/common.service.js'
import { djangoToJsBoolean } from '@helpers/utils'

export default {
  name: 'faq-form',
  mixins: [FormMixin],
  directives: { mask },
  data() {
    return {
      formdata: {
        name: '',
        phone: '+38',
        email: '',
        subject: '',
        question: '',
      },
      isPrefillForm: djangoToJsBoolean(window.is_auth),
    }
  },
  watch: {
    user: {
      handler(nval) {
        this.formdata = this.fillFormdata(nval)
      },
    },
  },
  methods: {
    send(data) {
      return faqFormResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: true, res })
          this.formdata.phone = '+38'
        })
    },
  },
}
</script>
