<template lang="pug">
  component(
    is="a"
    :class="genetatedClass"
    :href="href"
    :target="target"
  )
    slot
</template>

<script>
import bemMixin from './bem-mixin'

export default {
  name: 'bem-link',
  mixins: [
    bemMixin,
  ],
  props: {
    href: String,
    block: {
      default: () => 'de-button',
    },
  },
}
</script>
