<script>
import { trackedDeleteResource } from '@services/cabinet.service.js'

export default {
  name: 'tracked-delete',
  props: {
    idProduct: String,
  },
  methods: {
    deleteTrackedProduct() {
      return trackedDeleteResource.execute({}, { idProduct: this.idProduct })
        .then(() => {
          // emit event to update products list on product compare page
          this.$emit('delete-item', this.idProduct)
        })
    },
  },
  render() {
    return this.$scopedSlots.default({
      action: this.deleteTrackedProduct,
    })
  },
}
</script>
