// // как более расширяемый вариант
// const DJANGO_TO_JS_MAP = {
//   True: true,
//   False: false,
//   None: null,
// }
// export function djangoToJsBoolean(variable) {
//   if (Object.keys(DJANGO_TO_JS_MAP).find(k => k === variable)) {
//     return DJANGO_TO_JS_MAP[variable]
//   }
//   return undefined
// }
export function djangoToJsBoolean(variable) {
  let value
  if ('True' === variable) value = true
  else if ('False' === variable) value = false
  else value = undefined
  return value
}

export function hasKey(obj, key) {
  return Object.prototype.hasOwnProperty.call(obj, key)
}

// export function hasLength(obj) {
//   return 0 < Object.keys(obj).length
// }

// export function isObjInArray(arr, key, value) {
//   const res = arr.find(el => el[key] === value)
//   return res !== undefined
// }

export function valueFromSelect(item, key) {
  let res
  if (Array.isArray(item)) {
    res = []
    item.forEach(el => {
      res.push(el[key])
    })
  } else {
    res = item[key]
  }
  return res
}

export function restoreObject(arr, key, value) {
  let item
  if (Array.isArray(value)) {
    item = []
    value.forEach(el => {
      item.push(arr.filter(itemEl => itemEl[key] === el))
    })
  } else {
    item = arr.filter(itemEl => itemEl[key] === value)
  }
  return item
}

export function checkEmptyFields(obj) {
  const formatedObj = {}
  Object.keys(obj).forEach(key => {
    if ('' === obj[key]) formatedObj[key] = null
    else formatedObj[key] = obj[key]
  })
  return formatedObj
}
