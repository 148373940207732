var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateBeforeSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('news-subscription-form-controller',{attrs:{"formdata":_vm.formdata},on:{"handle-response":function($event){return _vm.handleSubmit($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var categoriesList = ref.categoriesList;
var send = ref.send;
var isLoad = ref.isLoad;
var handleSelection = ref.handleSelection;
var checkCurrentOption = ref.checkCurrentOption;
return [_c('row',{attrs:{"align":"end","space":"sm","appearance":"spaced"}},[_c('cell',{attrs:{"cols":"12 6-md auto-lg"}},[_c('div',{staticClass:"news-subscription"},[_c('control-input',{attrs:{"rules":"custom_email|required","type":"email","label-class":"form__label","placeholder":_vm._('Введите имейл'),"input-label":_vm._('E-mail'),"class-name":"news-subscription__input","required":true,"name":"email"},model:{value:(_vm.formdata.email),callback:function ($$v) {_vm.$set(_vm.formdata, "email", $$v)},expression:"formdata.email"}}),_c('i',{staticClass:"news-subscription__icon news-subscription__icon--variant_1 de-icon de-icon--type_mail de-icon--size_sm de-icon--color_accent-1"})],1)]),_c('cell',{attrs:{"cols":"12 6-md auto-lg"}},[_c('div',{staticClass:"common-multiselect common-multiselect--fullwidth common-multiselect--white form"},[_c('control-multiselect',{attrs:{"options":categoriesList,"track-by":"id","label":"title","allow-empty":true,"label-class":"form__label","input-label":_vm._('Тема'),"placeholder":_vm._('Выберите тему'),"required":false,"multiple":true,"name":"category"},on:{"input":function($event){return handleSelection($event)},"select":function($event){return checkCurrentOption($event)}},scopedSlots:_vm._u([{key:"noResult",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Елементи не знайдено")))])]},proxy:true},{key:"noOptions",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._("Список порожній")))])]},proxy:true},{key:"selection",fn:function(ref){
var values = ref.values;
var search = ref.search;
return (values.length)?[_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.map(function (el) { return el.title; }).join(', ')))]),_c('div',{staticClass:"i-count"},[_vm._v(_vm._s(values.length))])]:undefined}}],null,true),model:{value:(_vm.formdata.category),callback:function ($$v) {_vm.$set(_vm.formdata, "category", $$v)},expression:"formdata.category"}})],1)]),_c('cell',{attrs:{"cols":"12 narrow-lg"}},[_c('bem-button',{staticClass:"button de-text de-text--size_2xs subs-btn",attrs:{"appearance":"fill-accent-1","rounded":"sm","width":"md","disable":isLoad},on:{"event":function($event){return _vm.validateBeforeSubmit(send)}}},[_vm._v(_vm._s(_vm._('Подписаться')))])],1)],1)]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }