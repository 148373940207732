<template lang="pug">
  include ../mixins
  .flex-column
    div(
      v-observe-visibility="{callback: checkVisibility, once: true, intersection: {rootMargin: '-150px', threshold: 0.8}}"
      v-if="isVisible"
    )
    slot(:addDatalayerEvent="addDatalayerClickEvent")
</template>

<script>
/* eslint-disable */
import { app } from './../../../main'
export default {
  props: {
    product: {},
    listKey: {},
  },

  data() {
    return {
      isVisible: false,
    }
  },

  created() {
    const timeout = 300
    setTimeout(() => {
      this.isVisible = true
    }, timeout)
  },

  methods: {
    checkVisibility(reached) {
      if (reached && this.product.id) {
        this.addDatalayerImpressionEvent()
      }
    },
    addDatalayerImpressionEvent() {
      app.updateImpressions(this.product)
    },
    addDatalayerClickEvent() {
      if (!this.product.id) return

      dataLayer.push({
        'ecommerce': {
          'currencyCode': 'UAH',
          'actionField': {
            'list': this.listKey,
          },
          'click': {
            'products': [this.product],
          }
        },
        'event': 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Clicks',
        'gtm-ee-event-non-interaction': 'False',
      })
    },
  },
}
</script>