<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(
      tabindex="-1"
      @click="$emit('close')"
    )
    bem-text.ma--bottom_3sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Сообщить когда будет в наличии')}}
    validation-observer(
      tag="form"
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          rules="username|required"
          type="text"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('Ваше имя')"
          v-model="formdata.name"
          :required="true"
          name="firstName"
        )
      +b.form.ma--bottom_sm
        control-input(
          rules="custom_email|required"
          type="email"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('E-mail')"
          v-model="formdata.email"
          :required="true"
          name="email"
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.de-text--size_2xs(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disable="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Отслеживать')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { trackedAddResourse } from '@services/common.service.js'
import { djangoToJsBoolean } from '@helpers/utils'
import { mapGetters, mapActions } from 'vuex'
import { USER_DATA_REQUEST } from '@store/action-types'

export default {
  name: 'tracked-form',
  mixins: [FormMixin],
  props: {
    objectItem: Object,
  },
  data() {
    return {
      formdata: {
        email: '',
        name: '',
      },
      isAuth: djangoToJsBoolean(window.is_auth),
    }
  },
  watch: {
    user: {
      handler({ firstName, lastName, email }) {
        this.formdata.name = `${firstName} ${lastName}`
        this.formdata.email = email
      },
    },
  },
  mounted() {
    if (this.isAuth) this.userRequest()
  },
  methods: {
    send(data) {
      this.formdata.product = this.objectItem.id
      return trackedAddResourse.execute({}, data)
        .then(() => {
          // console.log(res)
          window.location.reload()
        })
    },
    ...mapActions('cabinet', {
      userRequest: USER_DATA_REQUEST,
    }),
  },
  computed: {
    ...mapGetters('cabinet', {
      user: 'getUserData',
    }),
  },
}
</script>
