<template lang="pug">
  include ../mixins
  div
    +b.de-icon--type_close.--size_md.modal-close--common.BUTTON(@click="$emit('close')")
    +b.VIDEO.video-frame(
      ref="video"
      loop
      controls
    )
      +b.SOURCE.source(:src="objectItem.url")
</template>

<script>
export default {
  name: 'video-modal',
  props: {
    objectItem: {
      type: Object,
    },
  },

  mounted() {
    this.playVideo()
  },

  methods: {
    playVideo() {
      this.$refs.video.play()
    },
  },
}
</script>
