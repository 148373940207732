<template lang="pug">
  include ../mixins
  div
    bem-text.ma--bottom_sm(
      tag="p"
      size="md"
      weight="bold"
    ) {{_('Укажите E-mail')}}
    bem-text.ma--bottom_3sm(
      tag="p"
      size="2xs"
      height="xs"
    ) {{_('Для регистрации на сайте необходимо обязательно указать Email')}}
    validation-observer(
      tag="form"
      :action='url',
      method='POST'
      ref="observer"
      v-slot="{ errors }"
      @submit.prevent="validateBeforeSubmit"
    )
      +b.form.ma--bottom_sm
        control-input(
          rules="custom_email|required"
          type="email"
          label-class="form__label"
          class-name="form__input"
          :input-label="_('E-mail')"
          v-model="formdata.email"
          :required="true"
          name="email"
        )
        input(
          type='hidden',
          name='csrfmiddlewaretoken',
          :value='getCookie("csrftoken")'
        )
      +b.error-text.ma--bottom_sm.--top_sm.P(
        v-for="error in fieldErrors"
        v-if="fieldErrors.length > 0"
      ) {{ error }}
      bem-button.de-text--size_2xs(
        appearance="fill-accent-1"
        space="sm"
        width="full"
        rounded="sm"
        :disable="isLoad"
        @event="validateBeforeSubmit()"
      ) {{_('Отправить')}}
</template>

<script>
import FormMixin from '@mixins/form.mixin.js'
import { emailNotSetResource } from '@services/auth.service.js'
import { getCookie } from '@resource/cookie'

export default {
  name: 'set-email-form',
  props: ['url', 'partialToken'],
  mixins: [FormMixin],
  data() {
    return {
      formdata: {
        email: '',
      },
    }
  },
  methods: {
    getCookie,
    send(data) {
      this.$refs.observer.$el.submit()
      return emailNotSetResource.execute({}, data)
        .then(res => {
          this.handleResponse({ modal: false, res, redirect: true })
        })
    },
  },
}
</script>
